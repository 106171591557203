import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
} from '../../bootstrap/Card';
import Wizard, { WizardItem } from '../../Wizard';
import Button from '../../bootstrap/Button';
import Alert from '../../bootstrap/Alert';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import UploadImage from '../../../assets/img/wanna/susy/Screenshot 2024-10-14 111418.png';
import AuthContext from '../../../contexts/authContext';
import { startTransition, useContext } from 'react';
import Icon from '../../icon/Icon';
import { useNavigate } from 'react-router-dom';
import firebase from '../../../Firebase/firebase';

const WelcomeToMelodyRights = () => {
	const { auth } = firebase;
	const user = auth.currentUser;
	const today = new Date(); // Creates a new Date object containing the current date and time
	const dateString = today.toDateString();
	const navigate = useNavigate();

	const handleSubmit = () => {
		window.location.reload();
		navigate('/dashboard');
	};

	return (
		<PageWrapper title='Welcome'>
			<Page>
				<div className='my-5'>
					<Wizard
						color='info'
						noValidate
						className='shadow-3d-info w-100'
						onSubmit={handleSubmit}>
						<WizardItem id='step1' title='Important info'>
							<div className='mt-5 '>
								<p className='h1 mt-3'>
									<strong>
										{' '}
										Welcome to Melody Rights,{' '}
										<span className='text-info'>{user?.email}</span>{' '}
									</strong>
									<Icon icon='Check' color='success' size='10x' />
								</p>

								<div className='h3'>
									<strong>Your plan is now active! </strong>
								</div>
								<p className='h5 my-3'>
									Here are some important things to consider
								</p>

								<p className='h5 mx-4'>
									● Your uploads will reset on the <strong>1st</strong> of every
									month
								</p>
								<p className='h5 mx-4'>
									● Your billing cycle will be a monthly starting from today,{' '}
									{dateString}
								</p>
								<p className='h5 mx-4'>
									● If you have a larger quantity of tracks that you wish to
									submit immediately, please contact us and we can discuss it
									further.
								</p>
							</div>
						</WizardItem>
						<WizardItem id='step2' title='How to upload your music'>
							<Alert isLight icon='Info'>
								<strong>
									Below is an image of the upload page you will be using to submit
									your music.
								</strong>
							</Alert>
							<CardHeader>
								<CardLabel iconColor='info'>
									<CardTitle tag='div' className='h5'>
										<div className='row g-4 align-items-center justify-content-center'>
											<img src={UploadImage} className='w-75' />
										</div>
									</CardTitle>
								</CardLabel>
							</CardHeader>

							<CardBody>
								<div className='row'>
									<div className='col lg-12'></div>

									<div className=''>
										<p className='h1'>
											<strong>
												{' '}
												We require very basic data from you to complete a
												submission{' '}
											</strong>
										</p>

										<div className='h3 my-3'>
											<strong>
												For every submission please consider the following
											</strong>
										</div>

										<p className='h5 mx-4 my-3'>
											● Your track names will be the{' '}
											<strong>file names</strong>
										</p>
										<p className='h5 mx-4  my-3'>
											● Each submission will prompt you to inform us whether
											the music contains lyrics, explicit lyrics and is part
											of the public domain.
										</p>
										<p className='h5 mx-4  my-3'>
											● If any of these fields are true for one song but not
											for another, they must be submitted seperately.
										</p>
										<p className='h5 mx-4  my-3'>
											● The number of submissions you create does not impact
											your number of uploads remaining. Your uploads are based
											on the <strong>number of files uploaded</strong>. 10
											submissions of 1 song is the same as 1 submission of 10
											songs.
										</p>
										<p className='h5 mx-4  my-3'>
											● When selecting what sites to exclude, please note{' '}
											<strong>all sites are included by default</strong>, if
											you want your music on every platform we distribute to;
											simply press confirm and do not tick any boxes.
										</p>
									</div>
								</div>
							</CardBody>
						</WizardItem>
					</Wizard>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default WelcomeToMelodyRights;
