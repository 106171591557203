import React, { useState, useEffect } from 'react';

interface AnimatedTitleProps {
	text: string;
}

const AnimatedTitle: React.FC<AnimatedTitleProps> = ({ text }) => {
	const [colorIndexes, setColorIndexes] = useState<number[]>([]);

	useEffect(() => {
		setColorIndexes(Array(text.length).fill(0)); // Initialize all characters as black
	}, [text]);

	useEffect(() => {
		const transitionSteps = text.length * 2; // Total transitions needed (to blue, then to black)
		let step = 0; // Start the transition step

		// Immediate first update before interval starts
		setColorIndexes((prev) => {
			const newIndexes = [...prev];
			newIndexes[0] = 1; // Start from the first character
			return newIndexes;
		});

		const interval = setInterval(() => {
			step++; // Increment step at the start to align with current character index
			if (step === transitionSteps) step = 0; // Reset step if it reaches the end of cycle

			setColorIndexes((prev) => {
				const newIndexes = [...prev];
				const currentCharIndex = step % text.length; // Character index to update

				if (step < text.length) {
					// First half of the animation: turning characters blue
					newIndexes[currentCharIndex] = 1; // Turn current character blue
				} else {
					// Second half of the animation: reverting characters to black
					newIndexes[currentCharIndex] = 0; // Revert current character to black
				}

				return newIndexes;
			});
		}, 300);

		return () => clearInterval(interval); // Cleanup on unmount
	}, [text.length]);

	return (
		<h1>
			{text.split('').map((char, index) => (
				<strong>
					<span
						key={index}
						className='h1 mb-0 fw-bold'
						style={{
							color: colorIndexes[index] ? 'rgb(81, 108, 250)' : 'white',
							transition: 'color 0.3s ease',
							fontSize: '60px',
						}}>
						{char}
					</span>
				</strong>
			))}
		</h1>
	);
};

export default AnimatedTitle;
