import React, { FC, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { getDoc, doc, query, collection, where, getDocs } from 'firebase/firestore';
import classNames from 'classnames';
import { ApexOptions } from 'apexcharts';
import Checks from '../../bootstrap/forms/Checks';
import firebase from '../../../Firebase/firebase';
import Chart from '../../extras/Chart';
import Badge from '../../bootstrap/Badge';
import Button from '../../bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { CardTitle } from '../../bootstrap/Card';
import Alert from '../../bootstrap/Alert';
// this is the component I use to list everything in the my submissions page

// TODO -- I need to make the component clickable where it renders a new component that shows the correct information for the row (submission) I have clicked

// how am I going to achieve such a thing? Fuck knows its nebulose
interface MyCommonTableRowProps {
	id: string;
	name: string;
	handleModal: (id: string) => void;
	submissionStatus: boolean;
	createdAt: Timestamp;
}
const MyCommonTableRow: FC<MyCommonTableRowProps> = ({
	id,
	name,
	handleModal,
	submissionStatus,
	createdAt,
}) => {
	const { darkModeStatus } = useDarkMode();

	const naviate = useNavigate;
	const { db, auth } = firebase;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSTModalOpen, setIsSTModalOpen] = useState(false);

	const handleModals = (name: string) => {
		if (name == 'BMI') {
			toggleModal;
		} else {
			toggleSongTrust;
		}
	};
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};
	const toggleSongTrust = () => {
		setIsModalOpen(!isModalOpen);
	};

	const d = createdAt.toDate();

	return (
		<tr onClick={() => handleModal(id)} style={{ cursor: 'pointer', height: '90px' }}>
			<td>
				<strong>{id}</strong>
			</td>

			<td>
				<CardTitle className={submissionStatus ? 'text-success' : 'text-warning'}>
					{' '}
					{submissionStatus ? 'Approved' : ' In review'}{' '}
				</CardTitle>
			</td>

			<td>
				<CardTitle>{d.toDateString()}</CardTitle>
			</td>
		</tr>
	);
};

export default MyCommonTableRow;
