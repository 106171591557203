import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useContext } from 'react';
import Button from '../../bootstrap/Button';
import SubHeader, {
	SubHeaderLeft,
	SubheaderSeparator,
	SubHeaderRight,
} from '../../../layout/SubHeader/SubHeader';
import IMG1 from '../../../assets/img/wanna/susy/Infographic dark.jpg';
import { useNavigate } from 'react-router-dom';
import Card, {
	CardBody,
	CardLabel,
	CardSubTitle,
	CardTitle,
	CardHeader,
} from '../../bootstrap/Card';
import Page from '../../../layout/Page/Page';
import classNames from 'classnames';
import Icon from '../../icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import { createUseStyles } from 'react-jss';
import ThemeContext from '../../../contexts/themeContext';
import AnimatedTitle from '../AnimatedTitle';
import FrontSubHeader from '../subHeader/FrontSubHeader';

const useStyles = createUseStyles({
	// stylelint-disable
	intro: {
		zIndex: 200,
		overflow: 'hidden',
	},

	introImageWrapper: {
		zIndex: 1,
		paddingLeft: '0px',
	},

	introImage: {
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: '70%',
		width: '100%',
	},

	introContentWrapper: {
		zIndex: 2,
	},

	logoWrapper: {
		height: 300,
	},

	// stylelint-enable
});

const OurStory = () => {
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();

	const { mobileDesign } = useContext(ThemeContext);

	const classes = useStyles();
	return (
		<PageWrapper title='Contact'>
			<FrontSubHeader />

			<CardBody className='table-responsive h5' isScrollable>
				<CardHeader>
					<CardLabel icon='' iconColor='info' className='mt-5 mx-3'>
						<p className='h1' style={{ fontSize: '60px' }}>
							<strong>Hi, we're Melody Rights</strong>
						</p>
					</CardLabel>
				</CardHeader>
				<div id='intro' className='scroll-margin ' style={{ scrollBehavior: 'smooth' }}>
					<div
						style={{
							padding: '20px',
							borderRadius: '30px', // Rounded corners for visual effect
							transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
						}}
						className={classNames(
							'row',

							classes.intro,
							'd-flex justify-content-center my-3',
						)}>
						<div
							className={classNames(
								'col-lg-6',
								'col-6',
								classes.introImageWrapper,
								'd-flex justify-content-center',
							)}>
							<img
								src={IMG1}
								style={mobileDesign ? { width: '300px' } : { width: '100%' }}
							/>
						</div>
						<div
							className={classNames(
								'col-lg-6',
								'col-sm-12',
								'd-flex',

								'justify-content-center align-items-center',
								'',
								classes.introContentWrapper,
							)}>
							<div>
								<div className='col-12 shadow-3d-container mb-2'>
									<CardLabel className='mb-2' icon='MusicVideo'>
										<strong>The very beginning</strong>
									</CardLabel>
									<p>
										Melody Rights was forged out of decades of experience within
										the industry spent recognising difficulties and gaining a
										profitable understanding of the complex network of earning
										through music production. We simplified the process.
									</p>
									<CardLabel className='mb-2 mt-4' icon='PendingActions'>
										<strong>Our mission</strong>
									</CardLabel>
									<p>
										The digital architecture at Melody Rights was designed with
										a clear vision in mind; scalability. Our mission is to
										provide our customers with a portal that will distribute
										music throughout as many endpoints as possible and provide
										detailed reports of where music is used.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id='intro' className='scroll-margin ' style={{ scrollBehavior: 'smooth' }}>
					<div
						style={{
							padding: '20px',
							borderRadius: '30px', // Rounded corners for visual effect
							transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
						}}
						className={classNames(
							'row',

							classes.intro,
							'd-flex justify-content-center my-3',
						)}>
						<div
							className={classNames(
								'col-lg-6 ',
								'col-sm-12',
								classes.introImageWrapper,
								'd-flex justify-content-center',
							)}>
							<CardHeader className='bg-transparent'>
								<CardLabel>
									<CardTitle tag='div' className='h1'>
										The problem of music distribution – we solved it
									</CardTitle>
								</CardLabel>
							</CardHeader>
						</div>
						<div
							className={classNames(
								'col-lg-6',
								'col-sm-12',
								'd-flex',

								'justify-content-center align-items-center',
								'',
								classes.introContentWrapper,
							)}>
							<div>
								<div className='col-sm-12 shadow-3d-container mb-2'>
									<Card
										className={classNames(
											'transition-base rounded-2 mb-0 text-light mt-4',
											{
												'bg-l25-warning bg-l10-warning-hover':
													!darkModeStatus,
												'bg-lo50-warning bg-lo25-warning-hover':
													darkModeStatus,
											},
										)}
										shadow='sm'>
										<CardBody className=''>
											<div className='d-flex align-items-center justify-content-center'>
												<div className='flex-shrink-0'>
													<Icon
														icon='ReportProblem'
														size='4x'
														color='warning'
													/>
												</div>
												<div className='flex-grow-1'>
													<div className='fw-bold fs-2 mb-0'>
														The problem
													</div>
												</div>
											</div>
											<p className='mx-5'>
												The problem of music distribution is that major
												competitors of the industry all compete for
												streamlined solutions to get their clients music on
												major Digital Streaming platforms such as Spotify,
												Amazon Music and so on. These platforms are great
												for building your profile as an artist, but as the
												majority of us know; the earnings are low unless the
												song or artist is recognized publicly. There is no
												"widespread" distribution model that gives artists
												the opportunity to maximize the value of a
												recording.{' '}
											</p>
											<div className='d-flex align-items-center justify-content-center mt-4'>
												<div className='flex-shrink-0'>
													<Icon
														icon='AutoFixNormal'
														size='4x'
														color='warning'
													/>
												</div>
												<div className='flex-grow-1'>
													<div className='fw-bold fs-2 mb-0'>
														Our solution
													</div>
												</div>
											</div>
											<p className='mx-5'>
												Our solution is straightforward, provide all the
												gained knowledge that we have acquired over the
												years as a simplified service. We know where your
												music needs to be to see earnings without a public
												profile. This includes tracking your music whether
												it's played or sold in any digital or physical
												environemnt.
											</p>
										</CardBody>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id='intro' className='scroll-margin ' style={{ scrollBehavior: 'smooth' }}>
					<div
						style={{
							padding: '20px',
							borderRadius: '30px', // Rounded corners for visual effect
							transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
						}}
						className={classNames(
							'row',

							classes.intro,
							'd-flex justify-content-center my-3',
						)}>
						<div
							className={classNames(
								'col-lg-6 ',
								'col-sm-12',
								classes.introImageWrapper,
								'd-flex justify-content-center',
							)}>
							<Card
								className={classNames(
									'transition-base rounded-2 mb-0 text-light mt-4',
									{
										'bg-l25-secondary bg-l10-secondary-hover': !darkModeStatus,
										'bg-lo50-secondary bg-lo25-secondary-hover': darkModeStatus,
									},
								)}
								shadow='sm'>
								<CardBody className=''>
									<div className='d-flex align-items-center justify-content-center'>
										<div className='flex-shrink-0'>
											<Icon icon='LibraryMusic' size='4x' color='info' />
										</div>
										<div className='flex-grow-1'>
											<div className='fw-bold fs-2 mb-0 mx-2'>Musicians</div>
										</div>
									</div>
									<p className='mx-5'>
										The knowledge gained to even recognize that a service like
										Melody Rights can only be discovered by true titans of the
										music industry. The musicians behind the production of
										Melody Rights have been utilising these endpoints for years
										to see incredible profit through writing music.
									</p>
									<div className='d-flex align-items-center justify-content-center mt-4'>
										<div className='flex-shrink-0'>
											<Icon icon='Biotech' size='4x' color='info' />
										</div>
										<div className='flex-grow-1'>
											<div className='fw-bold fs-2 mb-0'>
												Software Engineers
											</div>
										</div>
									</div>
									<p className='mx-5'>
										A number of musicians wanting a streamlined solution to
										solve this problem of comprehensive registration required
										logical configuration from an experienced software engineer.
										Melody Rights is a digital infrastrcture reliant on a number
										of systems integrating to provide customers with global
										registrations for as little as $5 a month or £3.80.
									</p>
								</CardBody>
							</Card>
						</div>
						<div
							className={classNames(
								'col-lg-6',
								'col-sm-12',
								'd-flex',

								'justify-content-center align-items-center',
								'',
								classes.introContentWrapper,
							)}>
							<div>
								<div className='col-sm-12 shadow-3d-container'>
									<CardHeader className='bg-transparent'>
										<CardLabel>
											<CardTitle tag='div' className='h1'>
												Who are we?
											</CardTitle>
										</CardLabel>
									</CardHeader>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id='intro' className='scroll-margin ' style={{ scrollBehavior: 'smooth' }}>
					<div
						style={{
							padding: '20px',
							borderRadius: '30px', // Rounded corners for visual effect
							transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
						}}
						className={classNames(
							'row',

							classes.intro,
							'd-flex justify-content-center',
						)}>
						<div
							className={classNames(
								'col-lg-6 ',
								'col-sm-12',
								classes.introImageWrapper,
								'd-flex justify-content-center',
							)}>
							<CardHeader className='bg-transparent'>
								<CardLabel>
									<CardTitle tag='div' className='h1'>
										What's next for us?
									</CardTitle>
								</CardLabel>
							</CardHeader>
						</div>
						<div
							className={classNames(
								'col-lg-6',
								'col-sm-12',
								'd-flex',

								'justify-content-center align-items-center',
								'',
								classes.introContentWrapper,
							)}>
							<div>
								<div className='col-sm-12 shadow-3d-container mb-2'>
									<Card
										className={classNames(
											'transition-base rounded-2 mb-0 text-light mt-4',
											{
												'bg-l25-primary bg-l10-primary-hover':
													!darkModeStatus,
												'bg-lo50-primary bg-lo25-primary-hover':
													darkModeStatus,
											},
										)}
										shadow='sm'>
										<CardBody className=''>
											<p className='mx-5'>
												We have a number of feature expansions in
												development, only a couple will be listed here.
											</p>
											<div className='d-flex align-items-center justify-content-center'>
												<div className='flex-shrink-0'>
													<Icon icon='Api' size='4x' color='info' />
												</div>

												<div className='flex-grow-1'>
													<div className='fw-bold fs-2 mb-0'>
														The Melody Rights API
													</div>
												</div>
											</div>
											<p className='mx-5'>
												We recognize that for a number of our customers
												using a web based platform is not efficient or
												ideal, we are working to provide larger
												organisations with an alternative solution for
												providing our system with music for distribution.
											</p>
											<div className='d-flex align-items-center justify-content-center mt-4'>
												<div className='flex-shrink-0'>
													<Icon
														icon='AddShoppingCart'
														size='4x'
														color='info'
													/>
												</div>
												<div className='flex-grow-1'>
													<div className='fw-bold fs-2 mb-0'>
														The Melody Rights Marketplace
													</div>
												</div>
											</div>
											<p className='mx-5'>
												Our goal is to get your music to as many endpoints
												as possible. We want your music used, you will have
												the option to include every submission in our
												marketplace. At this marketplace your music can be
												sold or given away for free with all users being
												aware that it is NOT royalty free music.
											</p>
										</CardBody>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardBody>
		</PageWrapper>
	);
};

export default OurStory;
