import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import CommonHeaderRight from '../pages/_layout/_asides/CommonHeaderRight';
import DefaultHeader from '../pages/_layout/_asides/DefaultHeader';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: 'submit-email', element: null },
	{ path: '/dashboard', element: null },
	{
		path: `/dashboard`,
		element: null,
	},
];

export default headers;
