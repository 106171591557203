import { sendEmailVerification } from 'firebase/auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import { startTransition } from 'react';
import firebase from '../../../Firebase/firebase';
import Button from '../../bootstrap/Button';
import Card, { CardTitle, CardBody } from '../../bootstrap/Card';
import SubHeader from '../../../layout/SubHeader/SubHeader';
import ThemeContext from '../../../contexts/themeContext';
import { useContext } from 'react';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import showNotification from '../../extras/showNotification';
import Blue from '../../../assets/img/blue 2 low top.png';
import Icon from '../../icon/Icon';

const FrontSubHeader = () => {
	const { mobileDesign } = useContext(ThemeContext);
	const { user, isActivePlan, setUser, setVerified, setActivePlan } = useContext(AuthContext);
	const [modalState, setModalState] = useState(false);
	const [verificationModal, setVerificationModal] = useState(false);
	const { auth, db } = firebase;
	const navigate = useNavigate();

	const handleEmail = () => {
		if (user) {
			console.log(user);
			sendEmailVerification(user)
				.then(() => {
					// Handle success, maybe display a message to the user
					showNotification(
						'Success',
						'Please check your inbox for the verification email',
					);
					if (setUser) {
						setUser(null);
						setVerified(false);
					}
					navigate('/submit-email');
				})
				.catch((error) => {
					// Handle errors, such as network issues or invalid user state
					showNotification('Error', 'Please sign in to a valid account to send an email');
				});
		} else {
			showNotification('Error', 'Please sign in to a valid account to send an email');
			// Optionally, handle the UI feedback about no user being logged in
		}
	};
	const handleNavigate = () => {
		navigate('/view-plans');
	};
	const handleModal = () => {
		setModalState(!modalState);
	};
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
			console.log(firebaseUser);
			if (firebaseUser) {
				// If a user is signed in
				if (setUser) {
					setUser(firebaseUser);
				}
				if (firebaseUser && !firebaseUser.emailVerified) {
					setTimeout(() => {
						setVerificationModal(true);
					}, 4000);
				}

				const userDocRef = doc(db, 'users', firebaseUser.uid);

				if (firebaseUser.emailVerified) {
					try {
						const userDoc = await getDoc(userDocRef);
						if (userDoc.exists()) {
							const userData = userDoc.data();
							// Set active plan from the user's document
							setActivePlan(userData.activePlan);
						} else {
							console.log('No such document!');
							setActivePlan(false); // Handle case where there is no document for the user
						}
					} catch (error) {
						console.error('Error fetching user data:', error);
						setActivePlan(false); // Handle potential errors, such as permissions issues
					}
				}

				// Optionally fetch and set additional user data here
			} else {
				// No user is signed in
				if (setUser) {
					setUser(null);
				}

				setActivePlan(false);
			}
		});

		// Cleanup the subscription
		return () => unsubscribe();
	}, []);
	const handleLogout = async () => {
		if (setUser) {
			setUser(null);
		}
		console.log('logges');
		setVerified(false);
		setActivePlan(false);
		await signOut(auth);
		navigate('/submit-email');
	};

	auth.onAuthStateChanged((user) => {
		if (user) {
			console.log('Authenticated user:', user);
		} else {
			console.log('No user signed in');
		}
	});

	return (
		<SubHeader className='bg-dark'>
			{!mobileDesign ? (
				<div className='d-flex justify-content-center align-items-center'>
					<img src={Blue} style={{ width: '70px' }} className='py-1' />

					<CardTitle className='text-muted'>
						<Button />
						<Button
							color='info'
							onClick={() => {
								startTransition(() => {
									navigate('/');
								});
							}}
							className='mx-3'
							size='lg'
							isLink
							isOutline>
							HOME
						</Button>
					</CardTitle>
					{user?.emailVerified && !isActivePlan ? (
						<CardTitle className='text-muted'>
							<Button />
							<Button
								color='info'
								isLink
								onClick={handleNavigate}
								className='mx-3'
								size='lg'
								isOutline>
								View pricing
							</Button>
						</CardTitle>
					) : user != null && !isActivePlan ? (
						<Button
							color='danger'
							onClick={handleModal}
							className='mx-3'
							isLink
							icon='Warning'>
							Email verification required
						</Button>
					) : user != null && isActivePlan ? (
						<CardTitle className='text-muted'>
							<Button />
							<Button
								color='info'
								isLink
								onClick={() => {
									startTransition(() => {
										navigate('/dashboard');
									});
								}}
								className='mx-3'
								size='lg'
								isOutline>
								DASHBOARD
							</Button>
						</CardTitle>
					) : (
						<></>
					)}

					<CardTitle className='text-muted'>
						<Button />
						<Button
							color='info'
							onClick={() => {
								startTransition(() => {
									navigate('/about-us');
								});
							}}
							className='mx-3'
							size='lg'
							isLink
							isOutline>
							FAQ
						</Button>
					</CardTitle>

					<CardTitle className='text-muted'>
						<Button />
						<Button
							color='info'
							onClick={() => {
								startTransition(() => {
									navigate('/our-story');
								});
							}}
							className='mx-3'
							size='lg'
							isLink
							isOutline>
							OUR STORY
						</Button>
					</CardTitle>
					<CardTitle className='text-muted'>
						<Button />
						<Button
							color='info'
							onClick={() => {
								startTransition(() => {
									navigate('/blogs');
								});
							}}
							className='mx-3'
							size='lg'
							isLink
							isOutline>
							BLOG
						</Button>
					</CardTitle>

					<CardTitle className='text-muted'>
						<Button />
						<Button
							color='info'
							onClick={() => {
								startTransition(() => {
									navigate('/contact');
								});
							}}
							className='mx-3'
							size='lg'
							isLink
							isOutline>
							CONTACT
						</Button>
					</CardTitle>
					{user != null ? (
						<CardTitle className='text-muted'>
							<Button />
							<Button
								color='info'
								onClick={handleLogout}
								className='mx-3'
								size='lg'
								isLink
								isOutline>
								LOGOUT
							</Button>
						</CardTitle>
					) : (
						<CardTitle className='text-muted'>
							<Button />
							<Button
								color='info'
								onClick={() => {
									startTransition(() => {
										navigate('/submit-email');
									});
								}}
								className='mx-3'
								size='lg'
								isLink
								isOutline>
								LOGIN & SIGN UP
							</Button>
						</CardTitle>
					)}
				</div>
			) : (
				<div className='d-flex justify-content-center'>
					<Dropdown
						direction='down' // 'up' || 'end' || 'down' || 'start'
						isButtonGroup={false}
						className='scrollable'>
						<DropdownToggle hasIcon>
							<Button color='success' isLight>
								Click to see more options
							</Button>
						</DropdownToggle>
						<DropdownMenu
							isAlignmentEnd={true}
							breakpoint='sm' // 'sm' || 'md' || 'lg' || 'xl' || 'xxl'
							size='md' // 'sm' || 'md' || 'lg'
						>
							<Card style={{ height: '230px', marginBottom: '-20px' }}>
								<CardBody>
									<div>
										{user?.emailVerified && !isActivePlan ? (
											<DropdownItem>
												<a>
													<div
														className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
														onClick={handleNavigate}>
														<Icon icon='Send' color='info' /> View plans
														page
													</div>
													<div className='col-auto' />
												</a>
											</DropdownItem>
										) : user != null && !isActivePlan ? (
											<DropdownItem>
												<a>
													<div
														className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
														onClick={handleEmail}>
														<Icon icon='Send' color='danger' /> Verify
														your email
													</div>
													<div className='col-auto' />
												</a>
											</DropdownItem>
										) : user != null && isActivePlan ? (
											<DropdownItem>
												<a>
													<div
														className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
														onClick={() => {
															startTransition(() => {
																navigate('/dashboard');
															});
														}}>
														<Icon icon='Send' color='info' /> View
														dashboard
													</div>
													<div className='col-auto' />
												</a>
											</DropdownItem>
										) : (
											<></>
										)}
										{user != null ? (
											<DropdownItem>
												<a>
													<div
														className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
														onClick={handleLogout}>
														<Icon icon='Class' color='info' /> Logout
													</div>
													<div className='col-auto' />
												</a>
											</DropdownItem>
										) : (
											<DropdownItem>
												<a>
													<div
														className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
														onClick={() => {
															startTransition(() => {
																navigate('/submit-email');
															});
														}}>
														<Icon icon='Send' color='info' /> Login or
														sign up
													</div>
													<div className='col-auto' />
												</a>
											</DropdownItem>
										)}
										<DropdownItem>
											<a>
												<div
													className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
													onClick={() => {
														startTransition(() => {
															navigate('/about-us');
														});
													}}>
													<Icon icon='QuestionAnswer' color='info' />{' '}
													Visit FAQ
												</div>
												<div className='col-auto' />
											</a>
										</DropdownItem>
										<DropdownItem>
											<a>
												<div
													className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
													onClick={() => {
														startTransition(() => {
															navigate('/our-story');
														});
													}}>
													<Icon icon='History' color='info' /> Our story
												</div>
												<div className='col-auto' />
											</a>
										</DropdownItem>
										<DropdownItem>
											<a>
												<div
													className='col text-nowrap overflow-hidden text-overflow-ellipsis text-bold'
													onClick={() => {
														startTransition(() => {
															navigate('/contact');
														});
													}}>
													<Icon icon='ContactPage' color='info' /> Contact
													us
												</div>
												<div className='col-auto' />
											</a>
										</DropdownItem>
									</div>
								</CardBody>
							</Card>
						</DropdownMenu>
					</Dropdown>
				</div>
			)}
		</SubHeader>
	);
};

export default FrontSubHeader;
