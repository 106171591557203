/**
 * this is data.
 *
 * my shit is at the bottom
 */
import { User } from 'firebase/auth';

export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {};

export const getDemoPagesMenu = (
	isActivePlan: boolean,
	isVerified: boolean,
	user: User | null,
) => ({
	...(user
		? {}
		: {
				login: {
					id: 'login',
					text: 'Login',
					path: 'submit-email',
					icon: 'Login',
				},
			}),
	...(!isActivePlan && user?.emailVerified
		? {
				upload: {
					id: 'upload',
					text: 'View Plans',
					path: 'view-plans',
					icon: 'Upgrade',
				},
			}
		: {}),
});

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'About our platform',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layoudt',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};

export const getMyPagesMenu = (isActivePlan: boolean, isVerified: boolean, user: User | null) => ({
	contact2: {
		id: 'contact',
		text: 'Dashboard',
		path: '/Dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
	...(isActivePlan
		? {
				plansPage: {
					id: 'plansPage',
					text: 'Upload Audio',
					path: '/upload-audio',
					icon: 'Upload',
					subMenu: null,
				},
			}
		: {}),

	...(isActivePlan && user?.emailVerified
		? {
				mySubmissions: {
					id: 'mysubmissions',
					text: 'My Submissions',
					path: '/my-submissions',
					icon: 'WebAsset',
					subMenu: null,
				},
			}
		: {}),
	...(user
		? {
				login: {
					id: 'dashboard',
					text: 'More about us',
					path: '/about-us',
					icon: 'Details',
					subMenu: null,
				},
			}
		: {}),

	contact: {
		id: 'contact',
		text: 'Contact Us',
		path: '/contact',
		icon: 'ContactMail',
		subMenu: null,
	},
	...(user
		? {
				login2: {
					id: 'dashboard',
					text: 'Profile Settings',
					path: '/my-profile',
					icon: 'SupervisorAccount',
					subMenu: null,
				},
				login3: {
					id: 'dashboard',
					text: 'Logout',
					path: '/Logout',
					icon: 'Logout',
					subMenu: null,
				},
			}
		: {}),
});

export const componentPagesMenu = {};
