import { useState, useContext, useEffect } from 'react';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import { signOut } from 'firebase/auth';
import Button from '../../bootstrap/Button';
import firebase from '../../../Firebase/firebase';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AuthContext from '../../../contexts/authContext';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import Page from '../../../layout/Page/Page';
import Icon from '../../icon/Icon';
import Card, { CardBody, CardTitle, CardFooter, CardLabel, CardHeader } from '../../bootstrap/Card';
import Accordion, { AccordionItem } from '../../bootstrap/Accordion';
import Checks from '../../bootstrap/forms/Checks';
import showNotification from '../../extras/showNotification';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../bootstrap/Spinner';

interface UserData {
	stripeSubscriptionId: string;
	// Add other properties as needed
}
const UpgradeProducerPlan = () => {
	const [modalState, setModalState] = useState(false);
	const navigate = useNavigate();
	const [currentPlan, setCurrentPlan] = useState('');

	const handleModal = () => {
		if (isLoading) {
			showNotification('error', 'Please wait until the process is finished', 'danger');
		} else {
			setModalState(!modalState);
		}
	};
	const { db, auth } = firebase;
	const handleSubmit = () => {
		setIsLoading(true);
		const planId = process.env.REACT_APP_STRIPE_LABEL_PLAN_KEY;
		if (planId) {
			updateSubscriptionPlan(planId);
		}
	};
	const { user, isActivePlan, setUser, setVerified, setActivePlan } = useContext(AuthContext);
	const updateSubscriptionPlan = async (newPlanId: string) => {
		if (!user) {
			console.error('User not authenticated');
			setIsLoading(false);
			return;
		}

		// Get the current user's subscriptionId from Firestore
		const userRef = doc(db, 'users', user.uid);
		const userDoc = await getDoc(userRef);

		if (!userDoc.exists()) {
			console.error('No user data found');
			setIsLoading(false);
			return;
		}

		const userData = userDoc.data() as UserData;
		const { stripeSubscriptionId } = userData;
		if (!stripeSubscriptionId) {
			console.error('No subscription ID found for the user');
			setIsLoading(false);
			return;
		}

		console.log('Updating subscription with ID:', stripeSubscriptionId);

		// Get Firebase ID token for security
		const token = await user.getIdToken();

		// Use fetch to call the Firebase function
		fetch('https://us-central1-melodyrights-3564d.cloudfunctions.net/updateSubscription', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`, // Use Firebase ID token for security
			},
			body: JSON.stringify({
				subscriptionId: stripeSubscriptionId,
				newPlanId: newPlanId,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log('Subscription updated successfully:', data);
				setIsLoading(false);
				showNotification('Success', 'Subscription updated successfully', 'success');
				updatePlanName();
				navigate('/dashboard'); // Redirect user as necessary
			})
			.catch((error) => {
				console.error('Error updating subscription:', error);
				setIsLoading(false);
				showNotification('Error', 'Failed to update subscription', 'danger');
			});
	};

	const updatePlanName = async () => {
		try {
			// Get the currently signed-in user
			const user = auth.currentUser;

			if (user) {
				// Reference to the Firestore document where `userId` equals the signed-in user's ID
				const userRef = doc(db, 'users', user.uid);

				// Fetch the document to check if it exists
				const userDoc = await getDoc(userRef);

				if (userDoc.exists()) {
					// Update the `currentPlanName` field to "Producer"
					await updateDoc(userRef, {
						currentPlanName: 'Label',
						numberOfUploadsRemaining: 50,
					});

					console.log('User plan updated to Producer');
				} else {
					console.error('No user document found');
				}
			} else {
				console.error('User not signed in');
			}
		} catch (error) {
			console.error('Error updating user plan:', error);
		}
	};
	useEffect(() => {
		if (!user || !user.emailVerified || !isActivePlan) {
			handleLogout();
		} else {
			checkData();
		}
	}),
		[];

	const handleLogout = async () => {
		if (setUser) {
			setUser(null);
		}
		console.log('logges');
		setVerified(false);
		setActivePlan(false);
		await signOut(auth);
		navigate('/submit-email');
	};

	const checkData = async () => {
		console.log(user?.uid);
		if (user && user.emailVerified && isActivePlan) {
			const userDocRef = doc(db, 'users', user.uid);
			try {
				const docSnap = await getDoc(userDocRef);
				if (docSnap.exists()) {
					const plan = docSnap.data().currentPlanName;
					if (plan === 'Label') {
						navigate('/dashboard');
					} else if (plan == 'Artist' || plan == 'Producer') {
						handlePlan(plan);
					} else {
						navigate('/dashboard');
					}
				} else {
					console.log('No such document!');
					return null;
				}
			} catch (error) {
				console.error('Error getting document:', error);
				return null;
			}
		} else {
			console.log('User is not logged in or email is not verified');
			return null;
		}
	};
	const [isLoading, setIsLoading] = useState(false);
	const handlePlan = (planName: string) => {
		setCurrentPlan(planName);
	};
	return (
		<PageWrapper title='Upgrade'>
			<Page>
				<SubHeader>
					<SubHeaderLeft>
						<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
							Back to dashboard
						</Button>
						<SubheaderSeparator />
						<span className='text-muted'>Upgrade to producer</span>
					</SubHeaderLeft>
				</SubHeader>
				<Card stretch data-tour='list'>
					<CardHeader>
						<CardLabel icon='ContactMail' iconColor='info' className='mt-5 mx-3'>
							<CardTitle tag='div' className='h3'>
								Upgrade your current plan from: {currentPlan}{' '}
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='table-responsive' isScrollable>
						<div className='justify-content-center d-flex'>
							<div className=''></div>
						</div>
						<div className='w-100 my-5'>
							<Card
								className='transition-base rounded-2 mb-0 text-dark bg-l25-info bg-l10-primary-hover'
								shadow='sm'>
								<CardHeader className='bg-transparent'>
									<CardLabel>
										<CardTitle tag='div' className='h5'>
											Your current plan
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									{currentPlan == 'Artist' ? (
										<div className='d-flex align-items-center pb-3'>
											<div className='flex-shrink-0'>
												<Icon icon='Info' size='4x' color='primary' />
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-3 mb-0'>£5 a month</div>
												<div className='fw-bold fs-3 mb-0'>
													5 uploads a month
												</div>
												<div className='fw-bold fs-3 mb-0'>
													Gaming marketplaces excluded
												</div>
											</div>
										</div>
									) : currentPlan === 'Producer' ? (
										<div className='d-flex align-items-center pb-3'>
											<div className='flex-shrink-0'>
												<Icon icon='Info' size='4x' color='primary' />
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-3 mb-0'>£10 a month</div>
												<div className='fw-bold fs-3 mb-0'>
													15 uploads a month
												</div>
												<div className='fw-bold fs-3 mb-0'>
													Gaming marketplaces Included
												</div>
											</div>
										</div>
									) : (
										''
									)}
								</CardBody>
							</Card>
							<Card
								className='transition-base rounded-2 mb-0 text-dark bg-l25-warning bg-l10-primary-hover mt-5'
								shadow='sm'>
								<CardHeader className='bg-transparent'>
									<CardLabel>
										<CardTitle tag='div' className='h5'>
											Your new plan
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='d-flex align-items-center pb-3'>
										<div className='flex-shrink-0'>
											<Icon icon='Info' size='4x' color='primary' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-3 mb-0'>£50 a month</div>
											<div className='fw-bold fs-3 mb-0'>
												100 uploads a month
											</div>
											<div className='fw-bold fs-3 mb-0'>
												Gaming marketplaces included
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='w-100 my-5'>
							<Button color='info' className='w-100' onClick={handleModal}>
								Continue
							</Button>
						</div>
						<div className='w-100 my-5'>
							<p className='h5'></p>
						</div>
					</CardBody>
				</Card>
			</Page>
			<Modal
				isOpen={modalState}
				setIsOpen={handleModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={true}
				isScrollable={false}
				isCentered
				size={'lg'}
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Confirm</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='container'>
						<p>
							Please note that you are on an active plan currently, you will receive a
							refund of your initial upgrade cost. But you will be charged for this
							month and next month on the cost of the new plan. You will not be
							charged until the end of next month following this upgrade.
						</p>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex justify-content-center'>
					{isLoading ? (
						<Spinner color={'success'} size={25} />
					) : (
						<Button
							color='success'
							onClick={() => {
								handleSubmit();
							}}>
							I acknowledge these terms and wish to upgrade my account
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default UpgradeProducerPlan;
