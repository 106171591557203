import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import firebase from '../../../Firebase/firebase';
import { httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import Button from '../../bootstrap/Button';
import showNotification from '../../extras/showNotification';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../contexts/authContext';
import { useContext } from 'react';
interface UserData {
	stripeSubscriptionId: string;
	// Add other properties as needed
}
const stripePromise = loadStripe(
	'pk_test_51PEobvRxUQ82pcqPejXFhhev06nJifSO5dPPfq4IlrZKqUMEm9LENlHrjHDwkho0EsZA2n5fqHUuUw9GZpytontL00We13xOT8',
);

const ChangeSub = () => {
	const { db } = firebase;
	const handleSubmit = () => {
		updateSubscriptionPlan('price_1Q4iQyRxUQ82pcqPfNvPP5Gm');
	};
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const updateSubscriptionPlan = async (newPlanId: string) => {
		if (!user) {
			console.error('User not authenticated');
			return;
		}

		// Get the current user's subscriptionId from Firestore
		const userRef = doc(db, 'users', user.uid);
		const userDoc = await getDoc(userRef);

		if (!userDoc.exists()) {
			console.error('No user data found');
			return;
		}

		const userData = userDoc.data() as UserData;
		const { stripeSubscriptionId } = userData;
		if (!stripeSubscriptionId) {
			console.error('No subscription ID found for the user');
			return;
		}

		console.log('Updating subscription with ID:', stripeSubscriptionId);

		// Get Firebase ID token for security
		const token = await user.getIdToken();

		// Use fetch to call the Firebase function
		fetch('https://us-central1-melodyrights-3564d.cloudfunctions.net/updateSubscription', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`, // Use Firebase ID token for security
			},
			body: JSON.stringify({
				subscriptionId: stripeSubscriptionId,
				newPlanId: newPlanId,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log('Subscription updated successfully:', data);
				showNotification('Success', 'Subscription updated successfully');
				navigate('/dashboard'); // Redirect user as necessary
			})
			.catch((error) => {
				console.error('Error updating subscription:', error);
				showNotification('Error', 'Failed to update subscription');
			});
	};

	return (
		<PageWrapper>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back to dashboard
					</Button>
					<SubheaderSeparator />
					<span className='text-muted'>Contact us</span>
				</SubHeaderLeft>
			</SubHeader>
			<Button onClick={handleSubmit}>click me to do some weird shit</Button>
		</PageWrapper>
	);
};

export default ChangeSub;
