import React, { startTransition } from 'react';
import Card, { CardBody, CardTitle, CardSubTitle, CardFooter } from '../../bootstrap/Card';
import Accordion, { AccordionItem } from '../../bootstrap/Accordion';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Button from '../../bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import FrontSubHeader from '../subHeader/FrontSubHeader';
const AboutUs = () => {
	const navigate = useNavigate();
	return (
		<PageWrapper title='More About us'>
			<FrontSubHeader />
			<Card>
				<CardBody className='d-flex justify-content-center align-items-center'>
					<div className='text-center my-5'>
						<CardTitle style={{ fontSize: '30px' }}>
							Find out more about what we do
						</CardTitle>
					</div>
				</CardBody>
				<Card>
					<CardBody className='d-flex justify-content-left align-items-center'>
						<div className='w-100 row'>
							<CardSubTitle
								className='my-5 d-flex justify-content-center'
								style={{ fontSize: '18px' }}>
								s
							</CardSubTitle>

							<Accordion id='faq' shadow='sm' className='w-100'>
								<AccordionItem id='faq1' title='The idea'>
									Melody Rights was forged out of decades of experience within the
									industry spent recognising difficulties and gaining an
									understanding of the complex network of earning through music
									production. We simplified it.
								</AccordionItem>
								<AccordionItem id='faq2' title='The mission'>
									To provide a portal which will distribute music throughout as
									many endpoints as possible.
								</AccordionItem>
								<AccordionItem
									id='faq3'
									title='The current problem of music distribution'>
									All big names within the distribution industry will get the
									songs on major DSPs, and DistroKid offer content ID registration
									at an additional cost. We will register with every major DSP,
									plus cover all your royalty registrations, plus send it to
									multiple stock websites and a mix of other potential income
									streams.
								</AccordionItem>
							</Accordion>
							<CardTitle className='mt-5 mb-2'>Why us?</CardTitle>
							<Accordion id='faq' shadow='sm' className='w-100'>
								<AccordionItem
									id='faq1'
									title='1. Comprehensive Royalty Collection'>
									Melody Rights collects royalties from multiple sources,
									including MCPS, The MLC, Writers' share, Publishers' share,
									Neighbouring rights, and Content ID, ensuring you receive all
									the earnings your music generates.Melody Rights collects
									royalties from multiple sources, including MCPS, The MLC,
									Writers' share, Publishers' share, Neighbouring rights, and
									Content ID, ensuring you receive all the earnings your music
									generates.
								</AccordionItem>
								<AccordionItem id='faq2' title='2. Global Reach'>
									Melody Rights partners with international collection societies
									to ensure your royalties are collected from around the world,
									maximizing your global earnings.
								</AccordionItem>
								<AccordionItem id='faq3' title='3. Simplified Administration'>
									Managing publishing rights can be complex and time-consuming.
									Melody Rights handles all the paperwork and administrative tasks
									for you, saving you valuable time.
								</AccordionItem>
								<AccordionItem
									id='faq4'
									title='4. Major Digital Platform Distribution'>
									Melody Rights distributes your music to leading streaming
									services and online stores such as Spotify, Apple Music, Amazon
									Music, and more, ensuring maximum exposure.
								</AccordionItem>
								<AccordionItem id='faq5' title='5. Mechanical Royalty Collection'>
									In addition to performance royalties, Melody Rights helps you
									collect mechanical royalties from physical sales and interactive
									streams.
								</AccordionItem>
								<AccordionItem id='faq6' title='6. Professional Support'>
									Gain access to a team of experts who can help manage your
									catalog, answer questions, and resolve issues with collection
									societies.
								</AccordionItem>
								<AccordionItem id='faq7' title='7. YouTube Monetization'>
									Melody Rights claims and monetizes user-generated content on
									YouTube through its Content ID service, capturing additional
									revenue streams.
								</AccordionItem>
								<AccordionItem id='faq8' title='8.Computer Game Asset Marketplaces'>
									Melody Rights distributes your music to computer game asset
									marketplaces, expanding your reach into the gaming industry.
								</AccordionItem>
								<AccordionItem id='faq9' title='9. Flexibility'>
									Melody Rights distributes your music to computer game asset
									marketplaces, expanding your reach into the gaming industry.
								</AccordionItem>
								<AccordionItem id='faq10' title='10. Detailed Reporting'>
									Receive comprehensive reports on your earnings, providing
									transparency and insights into how your music is performing
									globally.
								</AccordionItem>
							</Accordion>
						</div>
					</CardBody>
				</Card>
			</Card>
		</PageWrapper>
	);
};
export default AboutUs;
