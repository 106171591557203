import React, { lazy } from 'react';
import { dashboardPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import CrmDashboard from '../components/myCreatedComponents/CrmDashboard';
import AboutUs from '../components/myCreatedComponents/about/AboutUs';
import UploadPage from '../components/myCreatedComponents/upload/UploadPage';
import MySubmissions from '../components/myCreatedComponents/mySubmissionsPage/MySubmissions';
import UserInfoPage from '../components/myCreatedComponents/profilePage/ProfilePage';
import PricingPlan from '../components/myCreatedComponents/payment/PricingPlans';
import GoldPlan from '../components/myCreatedComponents/payment/GoldPlan';
import EnteprisePlan from '../components/myCreatedComponents/payment/EnteprisePlan';
import StripeContainer from '../components/myCreatedComponents/payment/CheckoutForm';
import ViewASubmission from '../components/myCreatedComponents/mySubmissionsPage/ViewASubmission';
import PaymentDashboard from '../components/myCreatedComponents/paymentDashboard/PaymentDashboard';
import { element } from 'prop-types';
import Contact from '../components/myCreatedComponents/contact/Contact';
import ChangeSub from '../components/myCreatedComponents/changeSubscription/ChangeSub';
import UpgradeProducerPlan from '../components/myCreatedComponents/upgradePlans/UpgradeProducerPlan';
import UpgradeLabelPlan from '../components/myCreatedComponents/upgradePlans/UpgradeLabelPlan';
import Logout from '../components/myCreatedComponents/Logout';
import OurStory from '../components/myCreatedComponents/about/OurStory';
import ListAllBlogs from '../components/myCreatedComponents/blogs/ListAllBlogs';
import SignedByRecordBlog from '../components/myCreatedComponents/blogs/SignedByRecordBlog';
import PPLBlog from '../components/myCreatedComponents/blogs/PPLBlog';
import FreeMusicDistroBLog from '../components/myCreatedComponents/blogs/FreeMusicDistroBlog';
import WelcomeToMelodyRights from '../components/myCreatedComponents/welcomePages/WelcomeToMelodyRights';
import MakeMusicOnline from '../components/myCreatedComponents/blogs/MakeMusicOnline';
import HowMuchRoyalties from '../components/myCreatedComponents/blogs/HowMuchRoyalties';
import WebsitesToUploadFree from '../components/myCreatedComponents/blogs/WebsitesToUploadFree';
import { Sign } from 'crypto';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};
// for(int i = 0; i < 2; i++){this.i = i * i }
const presentation = [
	/**
	 * Landing
	 */
	{
		path: '/',
		element: <CrmDashboard />,
	},
	{
		path: '/submit-email',
		element: <Login />,
	},

	{
		path: '/about-us',
		element: <AboutUs />,
	},
	{
		path: '/upload-audio',
		element: <UploadPage />,
	},
	{
		path: '/my-submissions',
		element: <MySubmissions />,
	},
	{
		path: '/my-profile',
		element: <UserInfoPage />,
	},
	{
		path: 'view-plans',
		element: <PricingPlan />,
	},
	{
		path: 'silverplan',
		element: <StripeContainer />,
	},
	{
		path: 'goldplan',
		element: <GoldPlan />,
	},
	{
		path: 'enterpriseplan',
		element: <EnteprisePlan />,
	},
	{
		path: 'view-submission/:id',
		element: <ViewASubmission />,
	},
	{
		path: '/dashboard',
		element: <PaymentDashboard />,
	},
	{
		path: '/contact',
		element: <Contact />,
	},
	{
		path: 'change-subscription',
		element: <ChangeSub />,
	},
	{
		path: '/upgrade-producer',
		element: <UpgradeProducerPlan />,
	},
	{
		path: '/upgrade-label',
		element: <UpgradeLabelPlan />,
	},
	{
		path: '/Logout',
		element: <Logout />,
	},
	{
		path: 'our-story',
		element: <OurStory />,
	},
	{
		path: '/blogs',
		element: <ListAllBlogs />,
	},
	{
		path: '/how-to-get-signed-by-record-label',
		element: <SignedByRecordBlog />,
	},
	{
		path: '/how-much-does-PPL-licence-cost',
		element: <PPLBlog />,
	},
	{
		path: '/best-free-music-distributor',
		element: <FreeMusicDistroBLog />,
	},
	{
		path: 'welcome',
		element: <WelcomeToMelodyRights />,
	},
	{
		path: '/make-music-online',
		element: <MakeMusicOnline />,
	},
	{
		path: '/how-much-royalties',
		element: <HowMuchRoyalties />,
	},
	{
		path: 'websites-to-upload-free',
		element: <WebsitesToUploadFree />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const contents = [...presentation];

export default contents;
