import React, { useContext } from 'react';
import { RouteProps } from 'react-router-dom';
import { pageLayoutTypesPagesMenu } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';
import AuthContext from '../contexts/authContext';
import Contact from '../components/myCreatedComponents/contact/Contact';
/**
 * routes for the sidebar
 *
 * which is mega important
 */

const asides: RouteProps[] = [
	{ path: '/submit-email', element: null },
	{ path: '/dashboard', element: <DefaultAside /> },
	{ path: '/my-submissions', element: <DefaultAside /> },
	{ path: '/submit-email', element: null },
];
export default asides;
