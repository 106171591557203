import React, { useContext, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import firebase from '../../../Firebase/firebase';
import AuthContext from '../../../contexts/authContext';
import Button from '../../bootstrap/Button';
import Accordion, { AccordionItem } from '../../bootstrap/Accordion';
import PricingPlan from './PricingPlans';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import ProfilePageHeader from '../ProfilePageHeader';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';

import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import { useNavigate } from 'react-router';
import Spinner from '../../bootstrap/Spinner';
import showNotification from '../../extras/showNotification';
import {
	getFirestore,
	collection,
	query,
	where,
	getDocs,
	updateDoc,
	doc,
	getDoc,
} from 'firebase/firestore';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const CheckoutForm = () => {
	const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const { user } = useContext(AuthContext); // Use useContext here at the top level
	const TABS = {
		ACCOUNT_DETAIL: 'Account Details',
		PASSWORD: 'Password',
		MY_WALLET: 'Active Plan',
	};
	/**
	 *
	 * @param paymentMethodId
	 * @param email
	 * @param planId
	 * @returns
	 */
	const { auth, db } = firebase;
	const activateUserPlan = async (stripeCustomerId: string, subscriptionId: string) => {
		// Get the Firestore service
		const user = auth.currentUser; // Get the currently logged-in user

		if (user) {
			const usersCollection = query(collection(db, 'users'), where('userId', '==', user.uid));
			try {
				const querySnapshot = await getDocs(usersCollection);
				if (!querySnapshot.empty) {
					const userDoc = querySnapshot.docs[0]; // Assuming userID is unique and returns one doc
					const userDocRef = doc(db, 'users', userDoc.id);

					// Update the 'activePlan' field to true
					await updateDoc(userDocRef, {
						activePlan: true,
						numberOfUploadsRemaining: 15,
						stripeCustomerId: stripeCustomerId,
						stripeSubscriptionId: subscriptionId,
						currentPlanName: 'Producer',
					});
					console.log("User's plan activated successfully");
				} else {
					console.log('No matching user document found');
					showNotification(
						'Error activating your account',
						'Please contact us immediately',
						'danger',
					);
					setGenreModalState(false);
				}
			} catch (error) {
				console.error('Error updating user document:', error);
				showNotification(
					'Error activating your account',
					'Please contact us immediately',
					'danger',
				);
				setGenreModalState(false);
			}
		} else {
			console.log('No user is currently logged in');
			showNotification(
				'Error activating your account',
				'Please contact us immediately',
				'danger',
			);
			setGenreModalState(false);
		}
	};
	const callFirebaseFunction = async (customerId: string, email: string, planId: string) => {
		if (!user) {
			console.error('User not authenticated');
			showNotification(
				'Error activating your payment',
				'Please contact us if the issue persists',
				'danger',
			);
			setGenreModalState(false);
			return;
		}

		const token = await user.getIdToken();

		fetch(
			'https://us-central1-melodyrights-3564d.cloudfunctions.net/createCustomerAndSubscribe',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`, // Use Firebase ID token for security
				},
				body: JSON.stringify({
					email: email,
					customerId: customerId,
					planId: planId,
				}),
			},
		)
			.then((response) => response.json())
			.then((data) => {
				setGenreModalState(false);
				activateUserPlan(data.customerId, data.subscriptionId);
				showNotification('Success', 'Customer created successfully');
				updateUserField();
				navigate('/');
			})
			.catch((error) => {
				showNotification(
					'Error activating your payment',
					'Please contact us if the issue persists',
					'danger',
				);
				setGenreModalState(false);
			});
	};

	const callCreateSubscriptionWithPayment = async (
		paymentMethodId: string,
		email: string,
		amount: number,
		planId: string,
		customerId?: string, // Optional for new customers
	) => {
		if (!user) {
			console.error('User not authenticated');
			showNotification('Error', 'Please log in to complete the transaction', 'danger');
			return;
		}

		try {
			// Get the Firebase authentication token for security
			const token = await user.getIdToken();

			// Call the Firebase function
			const response = await fetch(
				'https://us-central1-melodyrights-3564d.cloudfunctions.net/createSubscriptionWithPayment',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`, // Send Firebase ID token for authentication
					},
					body: JSON.stringify({
						email: email,
						paymentMethodId: paymentMethodId,
						amount: amount, // In cents (e.g., 5000 for $50)
						planId: planId, // Stripe plan ID
						customerId: customerId || null, // Optional if customer exists
					}),
				},
			);

			const data = await response.json();

			if (response.ok) {
				// Success, subscription created
				console.log('Subscription created successfully', data);
				showNotification('Success', 'Subscription created successfully', 'success');
				// You may want to redirect the user to the dashboard or show them their subscription status
			} else if (data.requiresAction) {
				// Handle 3D Secure or other required actions
				console.log('Requires additional action:', data);
				const { paymentIntentClientSecret } = data;

				// Confirm the payment using Stripe.js (3D Secure step)

				const { error } = await stripe!.confirmCardPayment(paymentIntentClientSecret);

				if (error) {
					console.error('Error confirming card payment:', error);
					showNotification(
						'Error',
						'Payment confirmation failed. Please try again.',
						'danger',
					);
				} else {
					// Payment succeeded after 3D Secure
					showNotification(
						'Success',
						'Payment confirmed and subscription created!',
						'success',
					);
				}
			} else {
				// Handle any other errors from the backend
				console.error('Error creating subscription:', data.error);
				showNotification(
					'Error',
					'Failed to create subscription. Please try again.',
					'danger',
				);
			}
		} catch (error) {
			console.error('Error calling Firebase function:', error);
			showNotification('Error', 'An error occurred. Please contact support.', 'danger');
		}
	};

	const updateUserField = async () => {
		const fieldName = 'numberOfUploadsRemaining';
		const newValue = 15;
		const db = getFirestore();
		const user = auth.currentUser;

		if (user) {
			try {
				// Query to find the document with the current user's ID
				const q = query(collection(db, 'users'), where('userId', '==', user.uid));
				const querySnapshot = await getDocs(q);

				// Assuming userId is unique and there is only one document per user
				if (!querySnapshot.empty) {
					querySnapshot.forEach(async (docSnapshot) => {
						// Update the field with the new value
						await updateDoc(doc(db, 'users', docSnapshot.id), {
							[fieldName]: newValue,
						});
						console.log(`Field '${fieldName}' updated to ${newValue}`);
					});
				} else {
					console.log('No document found for this user.');
					showNotification(
						'Error activating your account',
						'Please contact us if the issue persists',
						'danger',
					);
					setGenreModalState(false);
				}
			} catch (error) {
				console.error('Error updating document: ', error);
			}
		} else {
			console.log('No user is signed in.');
			showNotification(
				'Error activating your payment',
				'Please contact us if the issue persists',
				'danger',
			);
			setGenreModalState(false);
		}
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setGenreModalState(true);

		try {
			if (!stripe || !elements) {
				console.log('Stripe.js has not loaded yet.');
				showNotification(
					'Error',
					'Stripe is not available at the moment. Please try again.',
					'danger',
				);
				setGenreModalState(false);
				return;
			}

			const cardElement = elements.getElement(CardElement);
			if (!cardElement) {
				console.log('Card Element not found.');
				showNotification('Payment failed', 'Contact us if the issue persists', 'danger');
				setGenreModalState(false);
				return;
			}

			// Create the payment method
			const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					email: user?.email,
				},
			});

			// Check for any errors while creating the payment method
			if (paymentMethodError) {
				console.error('Error creating payment method:', paymentMethodError.message);
				showNotification(
					'Payment failed',
					'Invalid card details. Please try again.',
					'danger',
				);
				setGenreModalState(false);
				return; // Stop execution if there's a payment method error
			}

			const token = await user?.getIdToken();

			// Step 1: Call Firebase function to create subscription and PaymentIntent
			const response = await fetch(
				'https://us-central1-melodyrights-3564d.cloudfunctions.net/createSubscriptionWithPayment',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						email: user?.email,
						paymentMethodId: paymentMethod?.id,
						planId: process.env.REACT_APP_STRIPE_PRODUCER_PLAN_KEY,
					}),
				},
			);

			// Check if the request to create the subscription was successful
			if (!response.ok) {
				console.error('Error creating subscription:', response.statusText);
				showNotification(
					'Error',
					'Failed to create subscription. Please try again.',
					'danger',
				);
				setGenreModalState(false);
				return; // Stop execution if there's an issue with the subscription creation
			}

			const { clientSecret, requiresAction, customerId, subscriptionId } =
				await response.json();

			// Step 2: If 3D Secure is required, confirm the PaymentIntent
			if (requiresAction) {
				const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
					clientSecret,
					{
						payment_method: {
							card: cardElement,
							billing_details: {
								email: user?.email,
							},
						},
					},
				);

				// Handle 3D Secure payment confirmation errors
				if (confirmError) {
					console.error('Payment failed:', confirmError.message);
					showNotification(
						'Payment failed',
						'3D Secure authentication failed. Please try again.',
						'danger',
					);
					setGenreModalState(false);
					return; // Stop execution if 3D Secure fails
				}

				// If the payment intent is not successful
				if (paymentIntent.status !== 'succeeded') {
					console.log('Payment incomplete. Status:', paymentIntent.status);
					showNotification(
						'Payment failed',
						'Contact us if the issue persists.',
						'danger',
					);
					setGenreModalState(false);
					return; // Stop execution if payment is incomplete
				}
			}

			// Subscription and payment are successful
			console.log('Subscription created successfully!');
			activateUserPlan(customerId, subscriptionId);
			updateUserField();
			setGenreModalState(false);
			navigate('/');
			showNotification('Success', 'Subscription created successfully!', 'success');
		} catch (error) {
			console.error('Error during payment processing:', error);
			showNotification('Error', 'An unexpected error occurred. Please try again.', 'danger');
			setGenreModalState(false);
		}
	};
	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				color: '#32325d',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
	};

	const [activeTab, setActiveTab] = useState(TABS.ACCOUNT_DETAIL);
	const [genreModalState, setGenreModalState] = useState(false);
	const handleGenreModal = () => {
		setGenreModalState(!genreModalState);
	};
	useEffect(() => {
		const fetchUserData = async () => {
			try {
				// Get the current signed-in user
				const user = auth.currentUser;

				if (user) {
					// Reference to the Firestore document where `userId` equals the signed-in user's ID
					const userRef = doc(db, 'users', user.uid); // Assuming user.uid is the ID stored in Firestore

					// Fetch the document from Firestore
					const userDoc = await getDoc(userRef);

					if (userDoc.exists()) {
						// Access the isActivePlan field (and any other fields)
						const userData = userDoc.data();
						if (userData.isActivePlan == true) {
							navigate('/dashboard');
							showNotification('error', 'you are already on a plan', 'danger');
						} else if (userData.isActivePlan == false) {
						}
					} else {
						console.error('No user document found');
						navigate('/');
						showNotification('Error fetching user data', 'Please sign in', 'danger');
					}
				} else {
					console.error('No user signed in');
					navigate('/');
					showNotification('Error fetching user data', 'Please sign in', 'danger');
				}
			} catch (error) {
				console.error('Error fetching user data:', error);
				showNotification('Error fetching user data', 'error', 'danger');
			} finally {
			}
		};

		// Call the fetchUserData function when the component renders
		fetchUserData();
	}, []);
	return (
		<div style={{ width: '100%' }}>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back to view plans
					</Button>
					<SubheaderSeparator />

					<span className='text-muted'>Producer</span>
				</SubHeaderLeft>
			</SubHeader>
			<div className='d-flex justify-content-center align-items-center my-5'>
				<div
					className='d-flex justify-content-center align-items-center my-5'
					style={{ width: '100%' }}>
					<form onSubmit={handleSubmit} style={{ width: '80%' }}>
						<Card>
							<CardBody className='mx-5'>
								<CardTitle className='my-5'>
									Start your Producer subscription
								</CardTitle>
								<Card>
									<CardBody>
										<CardElement options={CARD_ELEMENT_OPTIONS} />
									</CardBody>
								</Card>

								<Button
									type='submit'
									isDisable={!stripe}
									color='success'
									className='mt-2'>
									Subscribe
								</Button>
								<CardFooter>
									<CardLabel>
										A minimum of a 6 month subscription is required for a payout
									</CardLabel>
								</CardFooter>
							</CardBody>
							<div></div>
						</Card>
						<Card>
							<CardBody className='mx-5'>
								<Accordion id='faq' shadow='sm'>
									<AccordionItem id='faq1' title='Can I cancel my subscription?'>
										<small className='text-muted'>
											Yes, of course. Cancellation of your subscription will
											stop payments from your account, however the music
											submitted with us will have to remain registered with
											these platforms. Only in specific cases can we request a
											takedown of every track from a specific artist.
										</small>
									</AccordionItem>
									<AccordionItem
										id='faq2'
										title='If I cancel my subscription, what happens to my music?'>
										Melody Rights can not take down music, once it's out there
										it's out there. In order to claim a payment, a minimum of a
										6 month subscription is required. This is to prevent fraud
										against our company, and give time for the user submitted
										music to generate income.
									</AccordionItem>
									<AccordionItem
										id='faq3'
										title='If I start a subscription, when will I see income?'>
										Familiarity with the industry helps to answer this question.
										Income from royalties takes time, potentially years to build
										up. This is the nature of how they operate. Stock websites
										and gaming marketplaces on the other hand are more
										immediate. Tracks can be sold within a matter of weeks.
									</AccordionItem>
								</Accordion>
								<Button
									onClick={() => {
										setGenreModalState(true);
									}}>
									click
								</Button>
							</CardBody>
						</Card>
					</form>
				</div>
				<Modal
					isOpen={genreModalState}
					setIsOpen={handleGenreModal}
					titleId='exampleModalLabel'
					isStaticBackdrop={true}
					isScrollable={false}
					isCentered
					fullScreen={false}>
					<Card className='d-flex justify-content-center align-items-center'>
						<CardBody>
							<ModalTitle id='faq1'>Creating your subscription</ModalTitle>
						</CardBody>
					</Card>

					<ModalBody className='d-flex justify-content-center align-items-center'>
						<Spinner color={'info'} size={50} />
					</ModalBody>
				</Modal>
			</div>
		</div>
	);
};

const GoldPlan = () => (
	<Elements stripe={stripePromise}>
		<CheckoutForm />
	</Elements>
);

export default GoldPlan;
