import React, { startTransition, useContext, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'; // <-- Import react-router-dom first
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { sendEmailVerification } from 'firebase/auth';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import classNames from 'classnames';
// eslint-disable-next-line import/order
import PageWrapper from '../../layout/PageWrapper/PageWrapper'; // <-- Now import your local components
import Page from '../../layout/Page/Page';
import Button from '../bootstrap/Button';
import AuthContext from '../../contexts/authContext';
import ComparisonTable from './ComparisonTable';
import showNotification from '../extras/showNotification';
import Blue from '../../assets/img/blue 2 low top.png';
import Blue2 from '../../assets/img/wanna/susy/Infographic light.jpg';
import RichieWebp from '../../assets/img/wanna/LOGO LOOP.mov';
import StorybookLogo from '../../assets/img/colorNotes.png';
import StorybookLogoWebp from '../../assets/img/colorNotes.png';
import MRComparison from '../../assets/img/Melody Rights Comparison Table New Dark cropped.jpg';
import AnimatedTitle from './AnimatedTitle';
import ThemeContext from '../../contexts/themeContext';
import ImageSlider from './homeComponents/ImageSlider';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import Alert from '../bootstrap/Alert';
import CompetitorImage from '../../assets/img/Melody Rights Competitors Image.png';
import MRIMG from '../../assets/img/wanna/susy/stefany-andrade-GbSCAAsU2Fo-unsplash (1).jpg';
import MRIMG2 from '../../assets/img/wanna/susy/luke-chesser-JKUTrJ4vK00-unsplash.jpg';
import MRIMG3 from '../../assets/img/wanna/susy/keagan-henman-ASz990K-sns-unsplash.jpg';
import MRIMG4 from '../../assets/img/wanna/susy/pankaj-patel-eygpU6KfOBk-unsplash.jpg';
import MRIMG5 from '../../assets/img/wanna/susy/aleksandr-popov-3InMDrsuYrk-unsplash.jpg';
import MRIMG6 from '../../assets/img/wanna/susy/scott-graham-5fNmWej4tAA-unsplash.jpg';
import MRIMG7 from '../../assets/img/wanna/susy/aleksandr-popov-vCbKwN2IXT4-unsplash.jpg';
import MRIMG8 from '../../assets/img/wanna/susy/aleksandr-popov-hTv8aaPziOQ-unsplash.jpg';
const useStyles = createUseStyles({
	// stylelint-disable
	intro: {
		zIndex: 200,
		overflow: 'hidden',
	},

	introImageWrapper: {
		zIndex: 1,
		paddingLeft: '0px',
	},

	introImage: {
		backgroundImage: `url(${isWebpSupported() && RichieWebp})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: '80%',
		width: '100%',
	},

	introContentWrapper: {
		zIndex: 2,
	},

	logoWrapper: {
		height: 300,
	},

	bootstrapLogo: {
		height: '100%',
		backgroundImage: `url(${Blue})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	storybookLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? StorybookLogoWebp : StorybookLogo})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	formikLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? Blue : Blue})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	apexLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? Blue : Blue})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},
	// stylelint-enable
});

const HomeInformation = () => {
	const classes = useStyles();

	const navigate = useNavigate();
	const { user, isActivePlan, setUser, setVerified } = useContext(AuthContext);
	const [modalState, setModalState] = useState(false);
	const [registerModalState, setRegisterModalState] = useState(false);
	const [uploadModalState, setUploadModalState] = useState(false);
	const [earnModalState, setEarnModalState] = useState(false);
	const { mobileDesign } = useContext(ThemeContext);

	const handleEarnModal = () => {
		setEarnModalState(!earnModalState);
	};

	const handleUploadModal = () => {
		setUploadModalState(!uploadModalState);
	};

	const handleRegisterModal = () => {
		setRegisterModalState(!registerModalState);
	};
	const handleModal = () => {
		setModalState(!modalState);
	};
	const handleEmail = () => {
		if (user) {
			console.log(user);
			sendEmailVerification(user)
				.then(() => {
					// Handle success, maybe display a message to the user
					showNotification(
						'Success',
						'Please check your inbox for the verification email',
						'success',
					);
					if (setUser) {
						setUser(null);
						setVerified(false);
					}
					navigate('/submit-email');
				})
				.catch((error) => {
					// Handle errors, such as network issues or invalid user state
					showNotification('Error', 'Please sign in to a valid account to send an email');
				});
		} else {
			showNotification('Error', 'Please sign in to a valid account to send an email');
			// Optionally, handle the UI feedback about no user being logged in
		}
	};

	const items = [
		{
			src: '',
			altText: 'Slide 1',
			captionHeader: 'Slide 1 title',
			captionText: 'Slide 1 text',
		},
		{
			src: '',
			altText: 'Slide 2',
			captionHeader: 'Slide 2 title',
			captionText: 'Slide 2 text',
		},
		{
			src: '',
			altText: 'Slide 3',
			captionHeader: 'Slide 3 title',
			captionText: 'Slide 3 text',
		},
	];
	type Rotate = {
		rotateX: number;
		rotateY: number;
	};

	const [rotate, setRotate] = useState<Rotate>({ rotateX: 0, rotateY: 0 });

	// Handle mouse movement inside the div
	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const {
			offsetWidth: width,
			offsetHeight: height,
			offsetLeft: left,
			offsetTop: top,
		} = e.currentTarget;
		const x = e.clientX - left;
		const y = e.clientY - top;

		// Calculate rotation proportional to mouse position
		const rotateX = (y / height - 0.5) * 35; // Max 30 degrees on the Y-axis
		const rotateY = (x / width - 0.5) * -35; // Max 30 degrees on the X-axis

		setRotate({ rotateX, rotateY });
	};
	const [isHovered, setIsHovered] = useState(false);
	const [isImgHovered, setIsImgHovered] = useState(false);
	const [isTxtHovered, setIsTxtHovered] = useState(false);

	const [isImg1Hovered, setIsImg1Hovered] = useState(false);
	const [isImg2Hovered, setIsImg2Hovered] = useState(false);
	const [isImg3Hovered, setIsImg3Hovered] = useState(false);
	const [isImg4Hovered, setIsImg4Hovered] = useState(false);
	const [isImg5Hovered, setIsImg5Hovered] = useState(false);
	const [isImg6Hovered, setIsImg6Hovered] = useState(false);
	const [isRapImageHovered, setIsRapImageHovered] = useState(false);

	// Reset rotation on mouse leave
	const handleMouseLeave = () => {
		setRotate({ rotateX: 0, rotateY: 0 });
	};
	return (
		<div className=''>
			<div className='d-flex justify-content-center'>
				{mobileDesign ? <img src={Blue} style={{ width: '300px' }} /> : ''}
			</div>
			<div className='d-flex justify-content-center mb-5 p-3'>
				<div className='w-100'>
					<div className='d-flex justify-content-center'>WELCOME TO</div>
					<br />
					<div className='d-flex justify-content-center text-center'>
						<AnimatedTitle text='MELODY RIGHTS' />
					</div>
					<div className='d-flex justify-content-center display-6 mb-4 text-center'>
						A new standard in music distribution
					</div>
					<div className='d-flex justify-content-center text-center'>
						<p className='h1 mb-5'>
							Your <span className='text-info'>music </span>
							Your <span className='text-info'>royalties </span>
							Our <span className='text-info'>expertise</span>
						</p>
					</div>
					<div className='d-flex justify-content-center'>
						{user === null ? (
							<Button
								color='primary'
								className={mobileDesign ? 'w-100' : 'w-50'}
								size='lg'
								shadow='lg'
								icon='Class'
								tag='a'
								to='/submit-email'>
								Get started
							</Button>
						) : user != null && user.emailVerified && !isActivePlan ? (
							<Button
								color='primary'
								className={mobileDesign ? 'w-100' : 'w-50'}
								size='lg'
								shadow='lg'
								icon='Class'
								tag='a'
								to='/view-plans'>
								Activate plan
							</Button>
						) : user != null && !user.emailVerified && !isActivePlan ? (
							<Button
								color='primary'
								className={mobileDesign ? 'w-100' : 'w-50'}
								size='lg'
								shadow='lg'
								icon='Class'
								tag='a'
								onClick={handleModal}>
								Send verification email
							</Button>
						) : (
							<Button
								color='primary'
								size='lg'
								className={mobileDesign ? 'w-100' : 'w-50'}
								shadow='lg'
								icon='Class'
								tag='a'
								onClick={() => {
									startTransition(() => {
										navigate('/dashboard');
									});
								}}>
								Visit dashboard
							</Button>
						)}
					</div>
				</div>
			</div>

			<div className='d-flex justify-content-center'></div>
			<div
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={{
					position: 'relative', // Required for absolute positioning of the background
					transform: isHovered ? 'scale(1.01)' : 'scale(1)', // Scale up on hover
					boxShadow: isHovered ? '0 0 8px rgba(0,0,0,0.6)' : '0 0 4px rgba(0,0,0,0.3)', // Shadow effects
					padding: '3px',
					borderRadius: '30px',
					transition: 'all 0.3s ease-in-out',
					overflow: 'hidden', // Ensure the background does not bleed past the border radius
				}}
				className={classNames(
					'row my-5',
					classes.intro,
					'd-flex justify-content-center rounded',
				)}>
				{/* Background image div */}
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(108, 93, 211, 0.2)), url(${MRIMG7})`,
						backgroundSize: 'contain',
						backgroundPosition: 'center',
						opacity: isHovered ? 0.2 : 0.4, // Change opacity on hover
						zIndex: -1, // Ensure it stays behind the content
						transition: 'opacity 0.3s ease-in-out',
					}}
				/>

				{/* All your content here */}
				<div
					className={classNames(
						'col-lg-12 col-md-12 col-sm-12 my-5 d-flex justify-content-center',
						classes.introImageWrapper,
					)}>
					<div
						className='col-lg-6 col-md-12'
						style={mobileDesign ? { marginTop: '30px' } : { marginTop: '20px' }}>
						<h1 className='d-flex justify-content-center text-center'>
							<span
								className='h1 mb-0 fw-bold mx-1'
								style={
									mobileDesign
										? { fontSize: '50px', marginLeft: '-160px' }
										: { fontSize: '50px' }
								}>
								<span>Your music,</span>
								<span className='text-info'> everywhere </span>
							</span>
						</h1>
						<p className='h3 my-3 text-center'>
							We remove the admin side of music distribution. Royalty registrations,
							account creating and endless form filling shouldn’t take up your time.
							Music doesn’t come from paperwork, let us handle the details. With
							Melody Rights your music goes further while you stay creative.
						</p>
					</div>
				</div>
			</div>

			<div
				className='row justify-content-center align-items-center'
				style={{
					transform: isImgHovered ? 'scale(1.01)' : 'scale(1)',
					transition: 'all 0.3s ease-in-out',
					padding: '20px',
				}}
				onMouseEnter={() => setIsImgHovered(true)}
				onMouseLeave={() => setIsImgHovered(false)}>
				<div className='col-lg-6 col-md-12 text-center'>
					<p className='h1'>
						<strong>
							A new take on the music industry, we redefined the standard of music
							distribution
						</strong>
					</p>
				</div>
				<div className='col-lg-6 col-md-12'>
					<img
						src={MRComparison}
						className='img-fluid'
						style={{
							width: mobileDesign ? '100%' : 'auto',

							borderRadius: '50px',
						}}
					/>
				</div>
			</div>

			<div
				onMouseEnter={() => setIsRapImageHovered(true)}
				onMouseLeave={() => setIsRapImageHovered(false)}
				style={{
					position: 'relative', // Required for absolute positioning of the background
					transform: isRapImageHovered ? 'scale(1.03)' : 'scale(1)', // Scale up on hover
					boxShadow: isRapImageHovered
						? '0 0 8px rgba(108,93,211,0.6)'
						: '0 0 4px rgba(0,0,0,0.3)', // Shadow effects
					padding: '3px',
					borderRadius: '30px',
					transition: 'all 0.3s ease-in-out',
					overflow: 'hidden', // Ensure the background does not bleed past the border radius
				}}
				className={classNames(
					'row my-5',
					classes.intro,
					'd-flex justify-content-center rounded',
				)}>
				{/* Background image div */}
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(108, 93, 211, 0.4)), url(${MRIMG8}) `,
						backgroundSize: 'auto',
						backgroundPosition: 'center',
						opacity: isHovered ? 0.1 : 0.3, // Change opacity on hover
						zIndex: -1, // Ensure it stays behind the content
						transition: 'opacity 0.3s ease-in-out',
					}}
				/>

				{/* All your content here */}
				<div
					className={classNames(
						'col-lg-12 col-md-12 col-sm-12 my-5 d-flex justify-content-center',
						classes.introImageWrapper,
					)}>
					<div
						className='col-lg-6 col-md-12'
						style={mobileDesign ? { marginTop: '30px' } : { marginTop: '20px' }}>
						<h1 className='d-flex justify-content-center text-center'>
							<span
								className='h3 mb-0 mx-1'
								style={
									mobileDesign
										? { fontSize: '30px', marginLeft: '-160px' }
										: { fontSize: '30px' }
								}>
								<p className=' d-flex justify-content-center '>
									<span className=''>
										<strong>What do we do?</strong>
									</span>
								</p>
							</span>
						</h1>
						<p className='h3 text-center mb-3'>
							We provide you with a portal to the most widespread music distribution
							system in the world.
						</p>

						<h1 className='d-flex justify-content-center text-center'>
							<span
								className='h3 mb-0 mx-1'
								style={
									mobileDesign
										? { fontSize: '30px', marginLeft: '-160px' }
										: { fontSize: '30px' }
								}>
								<p className=' d-flex justify-content-center '>
									<span className=''>
										<strong>
											Where will we track your music being played or sold?
										</strong>
									</span>
								</p>
							</span>
						</h1>
						<p className='h4 text-center'>
							Any TV media, Youtube, in licenced public performances, any Radio
							broadcast, CDs and digital downloads, sales of sheet music and musical
							scores, any public establishment such as bars or retail stores, any
							physical item such as toys and novelty items, writers licencing deals,
							direct sales of social music sites and ad revenue, sales on gaming
							marketplace, sales on multiple stock sites. Oh, and did we mention any
							Digital streaming platform such as Spotify, Amazon Music, Apple Music,
							Pandora, Deezer, Youtube music.... and so on?
						</p>
					</div>
				</div>
			</div>

			<div className='container mt-5'>
				<div className='d-flex justify-content-center h1'>
					<p>
						<strong>All plans include</strong>
					</p>
				</div>
				<div className='row'>
					<div
						className='col-md-4 col-12 mb-4 text-center'
						style={{
							transform: isImg1Hovered ? 'scale(1.03)' : 'scale(1)',
							transition: 'all 0.3s ease-in-out',
						}}
						onMouseEnter={() => setIsImg1Hovered(true)}
						onMouseLeave={() => setIsImg1Hovered(false)}>
						<div>
							<img src={MRIMG2} className='img-fluid mb-3' />
							<h3>Embedded catalouge management</h3>
							<p>
								A service that can cost thousands, automatically configured with
								every plan. We ensure your music has all the data required to be
								distributed into any CMS system in the world.
							</p>
						</div>
					</div>
					<div
						className='col-md-4 col-12 mb-4 text-center'
						style={{
							transform: isImg2Hovered ? 'scale(1.03)' : 'scale(1)',
							transition: 'all 0.3s ease-in-out',
						}}
						onMouseEnter={() => setIsImg2Hovered(true)}
						onMouseLeave={() => setIsImg2Hovered(false)}>
						<div>
							<img src={MRIMG5} className='img-fluid mb-3' />
							<h3>Access to the most widespread distribution system in the world</h3>
							<p>
								This industry isn't just about getting your music onto Digital
								Streaming Platforms. We're here to do it all.
							</p>
						</div>
					</div>
					<div
						className='col-md-4 col-12 mb-4 text-center'
						style={{
							transform: isImg3Hovered ? 'scale(1.03)' : 'scale(1)',
							transition: 'all 0.3s ease-in-out',
						}}
						onMouseEnter={() => setIsImg3Hovered(true)}
						onMouseLeave={() => setIsImg3Hovered(false)}>
						<div>
							<img src={MRIMG6} className='img-fluid mb-3' />
							<h3>Scheduled reports of your music</h3>
							<p>
								With every payment we make to you, we also provide data of where
								your music was used, what genre and what generated your income.
							</p>
						</div>
					</div>
					<div
						className='col-md-4 col-12 mb-4 text-center'
						style={{
							transform: isImg4Hovered ? 'scale(1.03)' : 'scale(1)',
							transition: 'all 0.3s ease-in-out',
						}}
						onMouseEnter={() => setIsImg4Hovered(true)}
						onMouseLeave={() => setIsImg4Hovered(false)}>
						<div>
							<img src={MRIMG} className='img-fluid mb-3' />
							<h3>Optional exclusion of every site we distribute to</h3>
							<p>
								We often get asked about music existing on one of our distributed
								sites already. Not a problem, exclude it on submission and your good
								to go!
							</p>
						</div>
					</div>
					<div
						className='col-md-4 col-12 mb-4 text-center'
						style={{
							transform: isImg5Hovered ? 'scale(1.03)' : 'scale(1)',
							transition: 'all 0.3s ease-in-out',
						}}
						onMouseEnter={() => setIsImg5Hovered(true)}
						onMouseLeave={() => setIsImg5Hovered(false)}>
						<div>
							<img src={MRIMG4} className='img-fluid mb-3' />
							<h3>Automatic metadata extraction and assignment</h3>
							<p>
								Descriptions, keywords, durations, instrument and every other data
								field possible all automatically extracted and assigned. Let us
								handle it all.
							</p>
						</div>
					</div>
					<div
						className='col-md-4 col-12 mb-4 text-center'
						style={{
							transform: isImg6Hovered ? 'scale(1.03)' : 'scale(1)',
							transition: 'all 0.3s ease-in-out',
						}}
						onMouseEnter={() => setIsImg6Hovered(true)}
						onMouseLeave={() => setIsImg6Hovered(false)}>
						<div>
							<img src={MRIMG3} className='img-fluid mb-3' />
							<h3>Continuous registration of our new platforms</h3>
							<p>
								Our current platofrms we distribute to is just the beginning, when
								we register with a new platform. You will be notified and all music
								will immediately be uploaded.
							</p>
						</div>
					</div>
					<div
						className='d-flex justify-content-center w-100 align-items-center mt-5 text-center'
						style={{
							transform: isImgHovered ? 'scale(1.01)' : 'scale(1)',
							transition: 'all 0.3s ease-in-out',
						}}
						onMouseEnter={() => setIsImgHovered(true)}
						onMouseLeave={() => setIsImgHovered(false)}>
						<p className='h1'>
							<strong>
								{' '}
								What would you spend to register across all platforms if you chose
								not to distribute through Melody Rights?
							</strong>
						</p>
					</div>
					<div className='d-flex justify-content-center text-center'>
						<AnimatedTitle text='$1,118' />
					</div>
				</div>
			</div>

			<Modal
				isOpen={modalState}
				setIsOpen={handleModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>File Section</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Alert color='danger' icon='warning'>
							Continuing with the email verification process will log you out.
						</Alert>

						<p>Once the link in the email has been clicked, continue to log back in</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='success' onClick={handleEmail}>
						Send verification Email
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={registerModalState}
				setIsOpen={handleRegisterModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				size={'lg'}
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleRegisterModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Register</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='container'>
						<div className='row'>
							<div className='col-md-4'>
								<p className='h4 '>
									<span className='text-info mx-4'>1.</span>
									Create an account
								</p>
							</div>
							<div className='col-md-4'>
								<p className='h4 '>
									<span className='text-info mx-4'>2.</span>
									Verify your email
								</p>
							</div>
							<div className='col-md-4'>
								<p className='h4 '>
									<span className='text-info mx-4'>3.</span>
									Activate your plan
								</p>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex justify-content-center'>
					Your email must be verified to activate a plan
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={uploadModalState}
				setIsOpen={handleUploadModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				size={'lg'}
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleUploadModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Upload music</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='container'>
						<div className='row'>
							<div className='col-md-4'>
								<p className='h4 '>
									<span className='text-info'>1.</span>
									Prepare your files
								</p>
							</div>
							<div className='col-md-4'>
								<p className='h4 '>
									<span className='text-info mx-1'>2.</span>
									Use our portal
								</p>
							</div>
							<div className='col-md-4'>
								<p className='h4 '>
									<span className='text-info'>3.</span>
									Wait for the files to upload
								</p>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex justify-content-center'>
					You must have an active plan to upload
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={earnModalState}
				setIsOpen={handleEarnModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				size={'lg'}
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleEarnModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Earn</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='container'>
						<p className='h4'>
							We issue quarterly payments to our clients, aligning with industry
							standards and reflecting the schedule on which we receive payment
							reports.{' '}
						</p>
						<div className='row my-3 mt-5'>
							<div className='col-md-3'>
								<p className='h4 '>
									<span className='text-info'>Q1</span> - January
								</p>
							</div>
							<div className='col-md-3'>
								<p className='h4 '>
									<span className='text-info mx-1'>Q2</span> - April
								</p>
							</div>
							<div className='col-md-3'>
								<p className='h4 '>
									<span className='text-info'>Q3</span> - August
								</p>
							</div>
							<div className='col-md-3'>
								<p className='h4 '>
									<span className='text-info'>Q4</span> - December
								</p>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex justify-content-center'>
					You must have an active plan to receive payments
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default HomeInformation;
