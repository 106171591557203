import React, { FC, useState, ChangeEvent, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Wizard, { WizardItem } from '../../../components/Wizard';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Select from '../../../components/bootstrap/forms/Select';
import Label from '../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Avatar from '../../../components/Avatar';
import User1Webp from '../../../assets/img/wanna/wanna2.webp';
import User1Img from '../../../assets/img/wanna/wanna2.png';
import editPasswordValidate from '../../../pages/profilePage/EditPasswordValidate';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import AuthContext from '../../../contexts/authContext';
import firebase from '../../../Firebase/firebase';
import { sendPasswordResetEmail, signOut } from 'firebase/auth';
import { getDoc } from 'firebase/firestore';
import { doc, addDoc, collection } from 'firebase/firestore';
import Textarea from '../../bootstrap/forms/Textarea';
import Spinner from '../../bootstrap/Spinner';

interface IPreviewItemProps {
	title: string;
	value: any | any[];
}
const PreviewItem: FC<IPreviewItemProps> = ({ title, value }) => {
	return (
		<>
			<div className='col-3 text-end'>{title}</div>
			<div className='col-9 fw-bold'>{value || '-'}</div>
		</>
	);
};

type QueryData = {
	name: string;
	query: string;
	timestamp: Date;
};

interface IValues {
	firstName: string;
	lastName: string;
	displayName: string;
	emailAddress: string;
	addressLine: string;
	phoneNumber: string;
	addressLine2: string;
	city: string;
	state: string;
	zip: string;
	emailNotification: string[];
	pushNotification: string[];
	currentPassword?: string;
	newPassword?: string;
	confirmPassword?: string;
}
const validate = (values: IValues) => {
	const errors: IValues = {
		firstName: '',
		lastName: '',
		displayName: '',
		emailAddress: '',
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
		addressLine: '',
		addressLine2: '',
		phoneNumber: '',
		city: '',
		state: '',
		zip: '',
		emailNotification: [],
		pushNotification: [],
	};
	if (!values.firstName) {
		errors.firstName = 'Required';
	} else if (values.firstName.length < 3) {
		errors.firstName = 'Must be 3 characters or more';
	} else if (values.firstName.length > 20) {
		errors.firstName = 'Must be 20 characters or less';
	}

	if (!values.lastName) {
		errors.lastName = 'Required';
	} else if (values.lastName.length < 3) {
		errors.lastName = 'Must be 3 characters or more';
	} else if (values.lastName.length > 20) {
		errors.lastName = 'Must be 20 characters or less';
	}

	if (!values.displayName) {
		errors.displayName = 'Required';
	} else if (values.displayName.length > 30) {
		errors.displayName = 'Must be 20 characters or less';
	}

	if (!values.emailAddress) {
		errors.emailAddress = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
		errors.emailAddress = 'Invalid email address';
	}

	if (values.currentPassword) {
		if (!values.newPassword) {
			errors.newPassword = 'Please provide a valid password.';
		} else {
			errors.newPassword = '';

			if (values.newPassword.length < 8 || values.newPassword.length > 32) {
				errors.newPassword +=
					'The password must be at least 8 characters long, but no more than 32. ';
			}
			if (!/[0-9]/g.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one digit appear anywhere in the string. ';
			}
			if (!/[a-z]/g.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one lowercase letter appear anywhere in the string. ';
			}
			if (!/[A-Z]/g.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one uppercase letter appear anywhere in the string. ';
			}
			if (!/[!@#$%^&*)(+=._-]+$/g.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one special character appear anywhere in the string. ';
			}
		}

		if (!values.confirmPassword) {
			errors.confirmPassword = 'Please provide a valid password.';
		} else if (values.newPassword !== values.confirmPassword) {
			errors.confirmPassword = 'Passwords do not match.';
		}
	}

	return errors;
};
const UserInfoPage = () => {
	// my code
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files != null) {
			const file = event.target.files[0];
			setSelectedFile(file);
		}
	};

	const deleteImage = () => {
		setSelectedFile(null);
	};
	const navigate = useNavigate();

	const TABS = {
		ACCOUNT_DETAIL: 'Account Details',
		PASSWORD: 'Password',
		REQUEST: 'Cancel subscription',
	};
	const [activeTab, setActiveTab] = useState(TABS.ACCOUNT_DETAIL);

	const notificationTypes = [
		{ id: 1, name: 'New Order' },
		{ id: 2, name: 'New Customer' },
		{ id: 3, name: 'Order Status' },
	];

	const formik = useFormik({
		initialValues: {
			firstName: 'John',
			lastName: 'Doe',
			displayName: 'johndoe',
			emailAddress: 'johndoe@site.com',
			phoneNumber: '',
			addressLine: '',
			addressLine2: '',
			city: '',
			state: '',
			zip: '',
			emailNotification: ['2'],
			pushNotification: ['1', '2', '3'],
		},
		validate,
		onSubmit: () => {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Updated Successfully</span>
				</span>,
				"The user's account details have been successfully updated.",
			);
		},
	});

	const handleFinalSubmit = () => {
		setIsLoading(true);
		const queryData: QueryData = {
			name: userEmail,
			query: userText,
			timestamp: new Date(),
		};
		writeToQueriesCollection(queryData);
	};

	const writeToQueriesCollection = async (queryData: QueryData): Promise<void> => {
		try {
			await addDoc(collection(db, 'requestsToCancel'), queryData);
			setIsLoading(false);
			if (isActivePlan) {
				navigate('/dashboard');
			} else {
				navigate('/');
			}
			showNotification('success', 'We will get back to you shortly', 'success');
		} catch (e) {
			console.log(e);
			showNotification('Error', 'Submit failed', 'danger');
			setIsLoading(false);
		}
	};

	const { auth, db } = firebase;
	const user = auth.currentUser;

	const formikPassword = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		validate: editPasswordValidate,
		onSubmit: () => {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Updated Successfully</span>
				</span>,
				"The user's password have been successfully updated.",
			);
		},
	});
	const sendPasswordReset = async (email: string) => {
		try {
			await sendPasswordResetEmail(auth, email);
			showNotification('success', 'Password reset email sent', 'success');
		} catch (error) {
			showNotification('Error', 'Error sending the email', 'danger');
		}
	};

	const handleUpdate = () => {
		if (user?.email) {
			console.log(user.email);
			sendPasswordReset(user?.email);
		}
	};
	const [userEmail, setUserEmail] = useState('');
	const [userText, setUserText] = useState('');
	const [modalState, setModalState] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { isActivePlan, setUser, setVerified, setActivePlan } = useContext(AuthContext);

	useEffect(() => {
		if (user?.email != null) {
			setUserEmail(user?.email);
		}
	}, []);

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserText(e.target.value);
	};
	const [useAccountEmail, setUseAccountEmail] = useState(false);
	const handleChangeAccount = () => {
		if (user) {
			setUseAccountEmail(!useAccountEmail);
			if (userEmail != '') {
				setUserEmail('');
			}
			if (user.email != null) {
				setUserEmail(user.email);
			}
		} else {
			showNotification('Error', 'Please create an account', 'info');
		}
	};
	const isValidEmail = (email: string) => {
		const regex = new RegExp(
			/^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
		);
		return regex.test(email);
	};

	const handleShowModal = () => {
		const isValid = isValidEmail(userEmail);
		if (isValid) {
			if (userText.length > 10 && userText.length < 1000) {
				setModalState(!modalState);
			} else {
				showNotification('Error', 'Please provide a valid message', 'danger');
			}
		} else {
			showNotification('Error', 'Invalid email', 'danger');
		}
	};

	const formik2 = useFormik({
		enableReinitialize: true,
		initialValues: {
			signupemail: 'email@example.com',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string; signupemail?: string } =
				{};

			if (!values.signupemail) {
				errors.signupemail = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {},
	});

	return (
		<PageWrapper title='Edit Profile' isProtected isVerifiedProtected>
			<Page>
				<SubHeader>
					<SubHeaderLeft>
						<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
							Back to dashboard
						</Button>
						<SubheaderSeparator />

						<span className='text-muted'>Profile</span>
					</SubHeaderLeft>
				</SubHeader>
				<div className='row h-100 pb-3'>
					<div className='col-lg-4 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='AccountCircle'>
									<CardTitle tag='div' className='h5'>
										User Information
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Contacts'
											color='info'
											className='w-100 p-3'
											isLight={TABS.ACCOUNT_DETAIL !== activeTab}
											onClick={() => setActiveTab(TABS.ACCOUNT_DETAIL)}>
											{TABS.ACCOUNT_DETAIL}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='LocalPolice'
											color='info'
											className='w-100 p-3'
											isLight={TABS.PASSWORD !== activeTab}
											onClick={() => setActiveTab(TABS.PASSWORD)}>
											{TABS.PASSWORD}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='LocalPolice'
											color='info'
											className='w-100 p-3'
											isLight={TABS.REQUEST !== activeTab}
											onClick={() => setActiveTab(TABS.REQUEST)}>
											{TABS.REQUEST}
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-8 col-md-6'>
						{TABS.ACCOUNT_DETAIL === activeTab && (
							<div>
								<Card>
									<CardHeader>
										<CardLabel icon='Edit' iconColor='warning'>
											<CardTitle>Personal Information</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody className='pt-0'>
										<div className='row g-4'>
											<div className='col-md-12'>
												<CardTitle>Account email</CardTitle>
												<CardSubTitle>{user?.email}</CardSubTitle>
											</div>

											<div className='col-12'>
												<CardTitle>Track balance</CardTitle>
												<CardSubTitle>5</CardSubTitle>
											</div>
											<div className='col-12'>
												<CardTitle>Active Plan</CardTitle>
												<CardSubTitle>Artist</CardSubTitle>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						)}
						{TABS.PASSWORD === activeTab && (
							<Card
								stretch
								tag='form'
								noValidate
								onSubmit={formikPassword.handleSubmit}>
								<CardHeader>
									<CardLabel icon='LocalPolice' iconColor='info'>
										<CardTitle>{TABS.PASSWORD}</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='pb-0' isScrollable>
									<div className='row g-4'>
										<CardTitle className='my-3'>
											To reset your password, please click the button below
										</CardTitle>
										<CardSubTitle>
											An email will be sent to the account email, follow the
											instructions within the email to complete the process
										</CardSubTitle>
									</div>
								</CardBody>
								<CardFooter>
									<Button
										color='info'
										icon='send'
										className='w-100'
										onClick={handleUpdate}>
										Send email
									</Button>
								</CardFooter>
							</Card>
						)}
						{TABS.REQUEST === activeTab && (
							<Card className='mt-5'>
								<CardBody className='table-responsive'>
									<div className='justify-content-center d-flex'>
										<div className=''>{user?.email}</div>
									</div>
									<div className='w-100 my-5'>
										<Textarea
											value={userText}
											onChange={handleTextChange}
											placeholder='Enter your reason to cancel here, we will cancel your subscription and remove all your songs from our database.'
											style={{ height: '300px' }}></Textarea>
									</div>
									<div className='w-100 my-5'>
										<Button
											color='info'
											className='w-100'
											onClick={handleShowModal}>
											Confirm
										</Button>
									</div>
								</CardBody>
							</Card>
						)}
					</div>
				</div>
				<Modal
					isOpen={modalState}
					setIsOpen={handleShowModal}
					titleId='exampleModalLabel'
					isStaticBackdrop={false}
					isScrollable={false}
					isCentered
					size={'lg'}
					fullScreen={false}
					isAnimation>
					<ModalHeader setIsOpen={handleShowModal}>
						<ModalTitle id='exampleModalLabel'>
							<strong>Confirm</strong>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='container'>
							<p className='h4'>{user?.email} - Request to cancel</p>
							<div className='row my-3 mt-5'>Reason - {userText}</div>
						</div>
					</ModalBody>
					<ModalFooter className='d-flex justify-content-center'>
						{isLoading ? (
							<Spinner isSmall inButton isGrow />
						) : (
							<Button className='w-100' color='success' onClick={handleFinalSubmit}>
								Submit
							</Button>
						)}
					</ModalFooter>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default UserInfoPage;
