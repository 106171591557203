import React, { startTransition, useContext, useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Card, { CardBody, CardTitle, CardHeader, CardLabel } from '../../bootstrap/Card';
import Button from '../../bootstrap/Button';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import AuthContext from '../../../contexts/authContext';
import showNotification from '../../extras/showNotification';
import Checks from '../../bootstrap/forms/Checks';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Textarea from '../../bootstrap/forms/Textarea';
import firebase from '../../../Firebase/firebase';
import Spinner from '../../bootstrap/Spinner';
import MRImg from '../../../assets/img/wanna/susy/miguel-alcantara-9tXHBPbP93w-unsplash.jpg';
import MRImg2 from '../../../assets/img/wanna/susy/keagan-henman-kjSoX81DMEc-unsplash.jpg';
import MRImg3 from '../../../assets/img/wanna/susy/kelly-sikkema-HwU5H9Y6aL8-unsplash (1).jpg';
import MRImg4 from '../../../assets/img/wanna/susy/israel-palacio-Y20JJ_ddy9M-unsplash.jpg';
import MRImg5 from '../../../assets/img/wanna/susy/jens-thekkeveettil-dBWvUqBoOU8-unsplash.jpg';
import MRImg6 from '../../../assets/img/wanna/susy/namroud-gorguis-FZWivbri0Xk-unsplash.jpg';
import FrontSubHeader from '../subHeader/FrontSubHeader';

type QueryData = {
	name: string;
	query: string;
	timestamp: Date;
};
interface ILoginProps {
	isSignUp?: boolean;
}
const Contact = () => {
	const navigate = useNavigate();
	const [userEmail, setUserEmail] = useState('');
	const [userText, setUserText] = useState('');
	const [modalState, setModalState] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { user, isActivePlan } = useContext(AuthContext);
	const { db } = firebase;
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserEmail(e.target.value);
	};

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserText(e.target.value);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			signupemail: 'email@example.com',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string; signupemail?: string } =
				{};

			if (!values.signupemail) {
				errors.signupemail = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {},
	});

	const [useAccountEmail, setUseAccountEmail] = useState(false);

	const posts = [
		{
			id: 1,
			title: 'Websites to Upload Music for Free and Get Discovered',
			text: 'Learn how to make money through music online and how Melody Rights simplifies the process',
			img: MRImg5,
			link: '/websites-to-upload-free',
		},
		{
			id: 2,
			title: 'How much royalties do songwriters get: A comprehensive guide',
			text: 'Learn how to make money through music online and how Melody Rights simplifies the process',
			img: MRImg4,
			link: '/how-much-royalties',
		},
		{
			id: 3,
			title: 'How to sell music online and make money: A complete guide',
			text: 'Learn how to make money through music online and how Melody Rights simplifies the process',
			img: MRImg6,
			link: '/make-music-online',
		},
		{
			id: 4,
			title: 'Best Free Music Distributor for Independent Artists',
			text: 'Find the best free music distributor for independent artists. Learn about key features, distribution tips, and why Melody Rights is a top choice.',
			img: MRImg3,
			link: '/best-free-music-distributor',
		},
		{
			id: 5,
			title: 'How Much Does a PPL Licence Cost in the UK?',
			text: 'Learn about how PPL licence costs are calculated, understand the difference between PPL and PRS and cost saving strategies for managing music expenses',
			img: MRImg2,
			link: '/how-much-does-PPL-licence-cost',
		},
		{
			id: 6,
			title: 'How to Get Signed by a Record Label in 2024',
			text: 'How do you get Signed to a Record Label  - Pros and Cons of Signing with a Label.',
			img: MRImg,
			link: '/how-to-get-signed-by-record-label',
		},
	];

	return (
		<PageWrapper title='Blogs'>
			<FrontSubHeader />
			<Card stretch data-tour='list' className='w-100'>
				<CardHeader>
					<CardLabel iconColor='info' className='mt-5 mx-3'>
						<CardTitle tag='div' className='h1'>
							The latest from Melody Rights{' '}
						</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody className='table-responsive' isScrollable>
					<div className='container mt-5'>
						<div className='row'>
							{posts.map((post) => (
								<div
									key={post.id}
									className='col-md-4 col-12 mb-4'
									id={post.link}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										startTransition(() => {
											navigate(post.link);
										});
									}}>
									<div>
										<img
											src={post.img}
											alt={post.title}
											className='img-fluid mb-3'
										/>
										<h3>{post.title}</h3>
										<p>{post.text}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</CardBody>
			</Card>
		</PageWrapper>
	);
};

export default Contact;
