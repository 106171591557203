import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { User, onAuthStateChanged } from 'firebase/auth';
import firebase from '../Firebase/firebase';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { getFirestore, collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';

/**
 * A context for handling authenticaction
 *
 * Super mega secret shit
 *
 */
export interface IAuthContextProps {
	user: User | null; // Change this to use the Firebase User type
	setUser?: React.Dispatch<React.SetStateAction<User | null>>;
	userData: Partial<IUserProps>;
	isVerified: boolean;
	setVerified: (verified: boolean) => void;
	isActivePlan: boolean;
	setActivePlan: (verified: boolean) => void;
	tracksRemaining: number;
	setTracksRemaining: (tracks: number) => void;
	// You can keep or adjust this depending on what additional user data you need
}
export const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export const AuthContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<User | null>(null); // Adjusted to store Firebase User object or null
	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const { auth } = firebase;
	const { db } = firebase;
	const [isVerified, setIsVerified] = useState(false);
	const [isActivePlan, setActivePlan] = useState(false);
	const [tracksRemaining, setTracksRemaining] = useState(0);

	const fetchNumberOfUploadsRemaining = async () => {
		if (user) {
			try {
				// Query Firestore for the document where userId matches the current user's UID
				const q = query(collection(db, 'users'), where('userId', '==', user.uid));
				const querySnapshot = await getDocs(q);

				if (!querySnapshot.empty) {
					// Assuming userId is unique and there is only one document per user
					const userDoc = querySnapshot.docs[0];
					const data = userDoc.data();
					return data.numberOfUploadsRemaining; // Return the value of the field
				} else {
					console.log('No document found for this user.');
					return null;
				}
			} catch (error) {
				console.error('Error fetching document: ', error);
				return null;
			}
		} else {
			console.log('No user is signed in.');
			return null;
		}
	};

	// Maintain existing useEffect for userData if you're using additional custom user data
	useEffect(() => {
		if (user) {
			setUserData(getUserDataWithUsername(user.uid)); // Assuming `getUserDataWithUsername` accepts a UID
		} else {
			setUserData({});
		}
	}, [user]);

	useEffect(() => {
		fetchNumberOfUploadsRemaining().then((value) => {
			if (value != null) {
				setTracksRemaining(value);
			}
		});
	});
	const setVerified = (verified: boolean) => {
		setIsVerified(verified);
	};
	const setPlan = (plan: boolean) => {
		setActivePlan(plan);
	};

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
			isVerified,
			setVerified,
			isActivePlan,
			setActivePlan,
			tracksRemaining,
			setTracksRemaining,
		}),
		[user, userData, isVerified, isActivePlan, tracksRemaining],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
