import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { Calendar as DatePicker } from 'react-date-range';
import classNames from 'classnames';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Avatar from '../../Avatar';
import UserImageWebp from '../../../assets/img/wanna/wanna1.webp';
import UserImage from '../../../assets/img/wanna/wanna1.png';
import Button from '../../bootstrap/Button';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import Input from '../../bootstrap/forms/Input';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Label from '../../bootstrap/forms/Label';
import Select from '../../bootstrap/forms/Select';
import Popovers from '../../bootstrap/Popovers';

import BoxData from '../../myData/myData/BoxData';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import Icon from '../../icon/Icon';
import useSelectTable from '../../../hooks/useSelectTable';
import useDarkMode from '../../../hooks/useDarkMode';
import useTourStep from '../../../hooks/useTourStep';
import MyCommonTableRow from './MyCommonTabeRow2';
interface Props {
	setSitesToRemove: (values: string[]) => void;
	sitesToDistribute: string[];
	sitesToRemove: string[];
}

const BoxedSites: React.FC<Props> = ({ setSitesToRemove, sitesToDistribute, sitesToRemove }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();

	const [date, setDate] = useState<Date>(new Date());

	const [filterMenu, setFilterMenu] = useState<boolean>(false);
	const formik = useFormik({
		initialValues: {
			categoryName: 'Accepting submissions',
			companyA: true,
			companyB: true,
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			setFilterMenu(false);
			// alert(JSON.stringify(values, null, 2));
		},
	});

	const filteredData = BoxData.filter(
		(f) =>
			// Category
			f.category === formik.values.categoryName &&
			// Price

			//	Company
			((formik.values.companyA ? f.store === 'Royalty' : false) ||
				(formik.values.companyB ? f.store === 'Stock' : false)),
	);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { items, requestSort, getClassNamesFor } = useSortableData(filteredData);
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);
	useEffect(() => {
		console.log('sitesToRemove has been updated:', sitesToRemove);
	}, [sitesToRemove]);
	const handleCheckboxChange = (site: string) => {
		if (sitesToRemove.includes(site)) {
			setSitesToRemove(sitesToRemove.filter((item) => item !== site));
		} else {
			setSitesToRemove([...sitesToRemove, site]);
		}
	};
	return (
		<Card stretch data-tour='list'>
			<CardBody className='table-responsive' isScrollable>
				<table className='table table-modern table-hover'>
					<thead>
						<tr>
							<th scope='col'></th>
							<th
								scope='col'
								onClick={() => requestSort('id')}
								className='cursor-pointer text-decoration-underline'>
								{' '}
								<Icon
									size='lg'
									className={getClassNamesFor('id')}
									icon='FilterList'
								/>
							</th>

							<th scope='col'>Name</th>

							<th scope='col'>Royalty or stock?</th>
						</tr>
					</thead>
					<tbody>
						{onCurrentPageItems.map((i) => (
							<MyCommonTableRow
								key={i.id}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...i}
								selectName='selectedList'
								selectOnChange={() => {
									const { name } = i;
									handleCheckboxChange(name);
								}}
								selectChecked={sitesToRemove.includes(i.name)}
							/>
						))}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				data={items}
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
			/>
		</Card>
	);
};

export default BoxedSites;
