import { useContext, useEffect } from 'react';
import AuthContext from '../../contexts/authContext';
import { signOut } from 'firebase/auth';
import firebase from '../../Firebase/firebase';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
	const { setUser, setVerified, setActivePlan } = useContext(AuthContext);
	const navigate = useNavigate();
	useEffect(() => {
		handleLogout();
	}, []);

	const { auth } = firebase;

	const handleLogout = async () => {
		if (setUser) {
			setUser(null);
		}
		console.log('logges');
		setVerified(false);
		setActivePlan(false);
		await signOut(auth);
		navigate('/');
	};
	return <></>;
};

export default Logout;
