import { components } from 'react-big-calendar';
import BMILogo from '../../../assets/img/abstract/bmilogo.png';
import CloudBall from '../../../assets/img/abstract/cloud-ball.png';
import Quadrilateral from '../../../assets/img/abstract/quadrilateral.png';
import HardSharpDonut from '../../../assets/img/abstract/hald-sharp-donut.png';
import BendyRectangle from '../../../assets/img/abstract/bendy-rectangle.png';
import Infinity from '../../../assets/img/abstract/infinity.png';
import Octahedron from '../../../assets/img/abstract/octahedron.png';
import Triangle from '../../../assets/img/abstract/triangle.png';
import SquiglyGlobe from '../../../assets/img/abstract/squigly-globe.png';
import Dodecagon from '../../../assets/img/abstract/dodecagon.png';
import BeveledCube from '../../../assets/img/abstract/beveled-cube.png';
import Cylinder from '../../../assets/img/abstract/cylinder.png';

const BoxData: {
	id: number;
	image: string;
	name: string;
	category: string;
	series: { data: number[] }[];
	color: string;
	stock: number;
	store: string;
	file: string;
	description: string;
}[] = [
	{
		id: 1,
		image: BMILogo,
		name: 'BMI',
		category: 'Accepting submissions',
		series: [
			{
				data: [25, 66, 41, 89, 63],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 380,
		store: 'Royalty',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 2,
		image: CloudBall,
		name: 'Song Trust',
		category: 'Accepting submissions',
		series: [
			{
				data: [12, 24, 33, 12, 48],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 1245,
		store: 'Royalty',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 3,
		image: Quadrilateral,
		name: 'Band Camp',
		category: 'Accepting submissions',
		series: [
			{
				data: [34, 32, 36, 34, 34],
			},
		],
		color: String(process.env.REACT_APP_WARNING_COLOR),
		stock: 27,
		store: 'Stock',
		file: 'XD',
		description: 'this is bmi description',
	},
	{
		id: 4,
		image: HardSharpDonut,
		name: 'Audio Sparx',
		category: 'Accepting submissions',
		series: [
			{
				data: [54, 34, 42, 23, 12],
			},
		],
		color: String(process.env.REACT_APP_DANGER_COLOR),
		stock: 219,
		store: 'Stock',
		file: 'Sketch',
		description: 'this is bmi description',
	},
	{
		id: 6,
		image: Infinity,
		name: 'Pond 5',
		category: 'Accepting submissions',
		series: [
			{
				data: [23, 13, 34, 41, 38],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 219,
		store: 'Stock',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 7,
		image: Octahedron,
		name: 'MLC',
		category: 'Accepting submissions',
		series: [
			{
				data: [21, 34, 23, 12, 67],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 498,
		store: 'Royalty',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 8,
		image: Triangle,
		name: 'Identifyy',
		category: 'Accepting submissions',
		series: [
			{
				data: [18, 32, 26, 15, 34],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 219,
		store: 'Royalty',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 9,
		image: SquiglyGlobe,
		name: 'Deposit Photos',
		category: 'Accepting submissions',
		series: [
			{
				data: [18, 32, 26, 15, 34],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 219,
		store: 'Stock',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 10,
		image: Dodecagon,
		name: 'Reverb Nation',
		category: 'Accepting submissions',
		series: [
			{
				data: [18, 32, 26, 15, 34],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 219,
		store: 'Stock',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 11,
		image: BeveledCube,
		name: 'MCPS',
		category: 'Accepting submissions',
		series: [
			{
				data: [18, 32, 26, 15, 34],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 219,
		store: 'Royalty',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 12,
		image: Cylinder,
		name: 'Harry Fox Agency',
		category: 'Accepting submissions',
		series: [
			{
				data: [18, 32, 26, 15, 34],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 219,
		store: 'Royalty',
		file: 'Figma',
		description: 'this is bmi description',
	},
	{
		id: 13,
		image: Cylinder,
		name: 'Rident Royalties',
		category: 'Accepting submissions',
		series: [
			{
				data: [18, 32, 26, 15, 34],
			},
		],
		color: String(process.env.REACT_APP_SUCCESS_COLOR),
		stock: 219,
		store: 'Royalty',
		file: 'Figma',
		description: 'this is bmi description',
	},
];
export default BoxData;
