import React, { useState, useContext } from 'react';
import {
	getFirestore,
	collection,
	query,
	where,
	getDocs,
	serverTimestamp,
	orderBy,
} from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, sendEmailVerification, reload } from 'firebase/auth';
import { useEffect } from 'react';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import firebase from '../../../Firebase/firebase';
import data from '../../../common/data/dummyProductData';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import Icon from '../../../components/icon/Icon';
import useSelectTable from '../../../hooks/useSelectTable';
import useDarkMode from '../../../hooks/useDarkMode';
import useTourStep from '../../../hooks/useTourStep';
import MyCommonTableRow from '../../../components/myCreatedComponents/upload/MyCommonTabeRow';
import AuthContext from '../../../contexts/authContext';
import ViewASubmission from './ViewASubmission';
import { useNavigate } from 'react-router-dom';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';

type Submission = {
	id: string;
	name: string;
	numberOfFiles: number;
	submissionStatus: boolean;
	createdAt: Timestamp;
};

const MySubmissions: React.FC = () => {
	const navigate = useNavigate();
	const { user, setUser, isVerified, setVerified, setActivePlan } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { auth } = firebase;
	const { db } = firebase;

	const [submissions, setSubmissions] = useState<Submission[]>([]);
	const [filterMenu, setFilterMenu] = useState<boolean>(false);
	const [selectedSubmissionId, setSelectedSubmissionId] = useState<string>('');
	const formik = useFormik({
		initialValues: {
			minPrice: '',
			maxPrice: '',
			categoryName: 'Royalty',
			companyA: true,
			companyB: true,
			companyC: true,
			companyD: true,
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			setFilterMenu(false);
			// alert(JSON.stringify(values, null, 2));
		},
	});
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
			console.log(firebaseUser);
			if (firebaseUser) {
				// If a user is signed in
				if (setUser) {
					console.log('the user is being set');
					setUser(firebaseUser);
				}

				const userDocRef = doc(db, 'users', firebaseUser.uid);

				if (firebaseUser.emailVerified) {
					console.log('is this getting called?');
					try {
						const userDoc = await getDoc(userDocRef);
						if (userDoc.exists()) {
							console.log('is this getting called from exists?');
							const userData = userDoc.data();
							// Set active plan from the user's document
							console.log(userData.activePlan);
							setActivePlan(userData.activePlan);
						} else {
							console.log('No such document!');
							setActivePlan(false); // Handle case where there is no document for the user
						}
					} catch (error) {
						console.error('Error fetching user data:', error);
						setActivePlan(false); // Handle potential errors, such as permissions issues
					}
				}

				// Optionally fetch and set additional user data here
			} else {
				// No user is signed in
				if (setUser) {
					setUser(null);
				}

				setActivePlan(false);
			}
		});
		// Cleanup the subscription
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const fetchSubmissions = async () => {
			const user = auth.currentUser;
			if (user) {
				const q = query(
					collection(db, 'submissions'),
					where('userId', '==', user.uid),
					orderBy('timestamp', 'desc'),
				);
				const querySnapshot = await getDocs(q);
				const data = querySnapshot.docs.map((doc) => {
					const docData = doc.data();
					return {
						id: doc.id,
						name: docData.name || 'Submitted', // Use 'Unnamed' if name is missing
						numberOfFiles: docData.numberOfFiles || 0, // Default to 0 if numberOfFiles is missing
						submissionStatus: docData.underProcess,
						createdAt: docData.timestamp,
					};
				});
				setSubmissions(data);
			}
		};
		fetchSubmissions();
	}, [auth, db]);
	const filteredData = data.filter(
		(f) =>
			// Category
			f.category === formik.values.categoryName &&
			// Price
			(formik.values.minPrice === '' || f.price > Number(formik.values.minPrice)) &&
			(formik.values.maxPrice === '' || f.price < Number(formik.values.maxPrice)) &&
			//	Company
			((formik.values.companyA ? f.store === 'Company A' : false) ||
				(formik.values.companyB ? f.store === 'Company B' : false) ||
				(formik.values.companyC ? f.store === 'Company C' : false) ||
				(formik.values.companyD ? f.store === 'Company D' : false)),
	);

	const handleModal = (id: string) => {
		setSelectedSubmissionId(id);
		console.log(id);
		navigate(`/view-submission/${id}`);
	};

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(filteredData);
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);
	return (
		<PageWrapper title='My Submissions' isProtected isVerifiedProtected>
			<Page>
				<Card stretch data-tour='list'>
					<CardHeader>
						<CardLabel icon='Upload' iconColor='info'>
							<CardTitle tag='div' className='h5'>
								My Submissions{' '}
								<small className='ms-2'>total: {submissions.length}</small>
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='table-responsive' isScrollable>
						<table className='table table-modern table-hover'>
							<thead>
								<tr>
									<th scope='col' onClick={() => requestSort('price')}>
										Submission ID{' '}
										<Icon
											size='lg'
											className={getClassNamesFor('price')}
											icon='FilterList'
										/>
									</th>
									<th scope='col' onClick={() => requestSort('price')}>
										Status
										<Icon
											size='lg'
											className={getClassNamesFor('price')}
											icon='FilterList'
										/>
									</th>
									<th
										scope='col-6'
										onClick={() => requestSort('price')}
										style={{ width: '30%' }}>
										Created
									</th>
								</tr>
							</thead>
							<tbody>
								{submissions.map((i) => (
									<MyCommonTableRow
										key={i.id}
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...i}
										handleModal={handleModal}
										submissionStatus={i.submissionStatus}
										createdAt={i.createdAt}
									/>
								))}
							</tbody>
						</table>
					</CardBody>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default MySubmissions;
