import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import AuthContext from '../../../contexts/authContext';
import { doc, getDoc } from 'firebase/firestore';
import firebase from '../../../Firebase/firebase';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { CardTitle } from '../../bootstrap/Card';
import ThemeContext from '../../../contexts/themeContext';
import CommonDashboardIncome from './CommonDashboardIncome';

const PaymentDashboard = () => {
	const { user, setUser, isVerified, setVerified, setActivePlan } = useContext(AuthContext);
	const [modalState, setModalState] = useState(false);
	const handleModal = () => {
		setModalState(!modalState);
	};
	const navigate = useNavigate();
	const { auth, db } = firebase;

	const { setAsideStatus, asideStatus } = useContext(ThemeContext);

	useEffect(() => {
		setAsideStatus(true);
		const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
			if (firebaseUser) {
				// If a user is signed in
				if (setUser) {
					setUser(firebaseUser);
				}

				const userDocRef = doc(db, 'users', firebaseUser.uid);

				if (firebaseUser.emailVerified) {
					try {
						const userDoc = await getDoc(userDocRef);
						if (userDoc.exists()) {
							const userData = userDoc.data();
							// Set active plan from the user's document
							setActivePlan(userData.activePlan);
						} else {
							console.log('No such document!');
							setActivePlan(false); // Handle case where there is no document for the user
						}
					} catch (error) {
						setActivePlan(false); // Handle potential errors, such as permissions issues
					}
				}
				// Optionally fetch and set additional user data here
			} else {
				// No user is signed in
				if (setUser) {
					setUser(null);
				}

				setActivePlan(false);
			}
		});

		// Cleanup the subscription
		return () => unsubscribe();
	}, []);

	return (
		<PageWrapper>
			<CommonDashboardIncome />
		</PageWrapper>
	);
};

export default PaymentDashboard;
