import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import Dropdown, { DropdownItem, DropdownToggle, DropdownMenu } from '../../bootstrap/Dropdown';

import Button from '../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';

import Icon from '../../icon/Icon';
import Badge from '../../bootstrap/Badge';
import Accordion, { AccordionItem } from '../../bootstrap/Accordion';

import Checks from '../../bootstrap/forms/Checks';
import { CheckBox } from '../../icon/material-icons';

interface ChildProps {
	setIsInstrumental: (value: boolean) => void;
	isInstrumental: boolean;
	setIsExplicit: (value: boolean) => void;
	isExplicit: boolean;
	setSelectedGenre: (value: string) => void;
	selectedGenre: string;
	isPublicDomain: boolean;
	setIsPublicDomain: (value: boolean) => void;
}

const GenreComponent: React.FC<ChildProps> = ({
	setIsInstrumental,
	isInstrumental,
	setIsExplicit,
	isExplicit,
	setSelectedGenre,
	selectedGenre,
	isPublicDomain,
	setIsPublicDomain,
}) => {
	const [localGenre, setLocalGenre] = useState('');
	const [selectedSubGenre, setSelectedSubGenre] = useState('');
	const [state, setState] = useState(false);
	const [instrumental, setInstrumental] = useState(false);
	const [explicit, setExplicit] = useState(false);
	useEffect(() => {
		console.log('genre' + selectedGenre);
		setLocalGenre(selectedGenre);
	}, []);
	const genres = [
		'20s',
		'30s',
		'40s',
		'50s',
		'60s',
		'70s',
		'80s',
		'Acoustic',
		'African',
		'Ambience',
		'Asian',
		'Blues',
		'Celtic',
		'Chill Out',
		'Christmas',
		'Classical',
		'Club',
		'Country',
		'Dance',
		'Drum and Bass',
		'Dubstep',
		'Electronic',
		'Epic',
		'Espanic',
		'Folk',
		'Funk',
		'Funky House',
		'Hip Hop',
		'Horror',
		'House',
		'Hymns',
		'Indie Pop Rock',
		'Industrial',
		'Jazz',
		'Jungle',
		'Kids',
		'Metal',
		'National Anthems',
		'Nursery Rhymes',
		'Pop',
		'Reggae',
		'Rock',
		'Soul',
		'Techno',
		'Trance',
		'TV and Radio',
		'Vocal',
		'World',
	];
	const handleToggle = () => {
		setIsInstrumental(!isInstrumental); // This should work now
	};
	const handleExplicitToggle = () => {
		setIsExplicit(!isExplicit); // This should work now
	};
	const handleGenre = (genre: string) => {
		setSelectedGenre(genre);
		setLocalGenre(genre);
	};
	const handlePublicDomain = (answer: boolean) => {
		setIsPublicDomain(!isPublicDomain);
	};
	return (
		<>
			<Card>
				<CardHeader>
					<CardLabel icon='Description' iconColor='success'>
						<CardTitle>Tell us about your music</CardTitle>
					</CardLabel>
				</CardHeader>
				<Accordion id='faq' shadow='sm'>
					<AccordionItem
						id='faq1'
						title="My genre isn't directly listed, what should I do?">
						For now, select the closest matching genre and continue with the upload
						process until a Submission ID is generated. Once you have a submission ID,
						please contact support@melodyrights.com and include your requested genre and
						the submission ID. We will ensure that the tracks are distributed with the
						most accurate data that represents your music
					</AccordionItem>
				</Accordion>
			</Card>

			<div style={{ marginBottom: '30px' }} />
			<Card>
				<CardTitle style={{ paddingLeft: '10px', paddingTop: '10px' }}>
					{localGenre === '' ? (
						<span> No Genre Selected</span>
					) : (
						<span className='mx-4 h4'> {localGenre}</span>
					)}
				</CardTitle>
				<CardBody>
					<Dropdown
						isOpen={state} // Not required. Example: state
						setIsOpen={setState} // Not required. Example: setState
						direction='down' // 'up' || 'end' || 'down' || 'start'
						isButtonGroup={false}
						className='scrollable '>
						<DropdownToggle hasIcon>
							<Button color='info' isLight>
								Select a Genre
							</Button>
						</DropdownToggle>
						<DropdownMenu
							isAlignmentEnd={false}
							breakpoint='lg' // 'sm' || 'md' || 'lg' || 'xl' || 'xxl'
							size='lg' // 'sm' || 'md' || 'lg'
						>
							<Card style={{ height: '300px', marginBottom: '-20px' }}>
								<CardBody isScrollable>
									{genres.map((genre) => (
										<div key={genre}>
											<DropdownItem isDivider />
											<DropdownItem>
												<a
													onClick={() => {
														handleGenre(genre);
														setState(false);
													}}>
													<div className='col text-nowrap overflow-hidden text-overflow-ellipsis'>
														<Icon icon='Send' /> {genre}
													</div>
													<div className='col-auto' />
												</a>
											</DropdownItem>
										</div>
									))}
								</CardBody>
							</Card>
						</DropdownMenu>
					</Dropdown>
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle>Does this submission contain any lyrics?</CardTitle>
					<Checks
						type='checkbox'
						id='instrumental'
						label='Yes'
						onChange={handleToggle}
						checked={isInstrumental}
					/>
					<Checks
						type='checkbox'
						id='instrumental'
						label='No'
						onChange={handleToggle}
						checked={!isInstrumental}
					/>
					<CardTitle className='mt-5'>
						Does this submission contain any explicit content?
					</CardTitle>
					<Checks
						type='checkbox'
						id='explicit'
						label='Yes'
						onChange={handleExplicitToggle}
						checked={isExplicit}
					/>
					<Checks
						type='checkbox'
						id='explicit'
						label='No'
						onChange={handleExplicitToggle}
						checked={!isExplicit}
					/>
					<CardTitle className='mt-5'>Is this a public domain submission?</CardTitle>
					<Checks
						type='checkbox'
						id='publidomain'
						label='Yes'
						onChange={handlePublicDomain}
						checked={isPublicDomain}
					/>
					<Checks
						type='checkbox'
						id='publidomain'
						label='No'
						onChange={handlePublicDomain}
						checked={!isPublicDomain}
					/>
				</CardBody>
			</Card>
		</>
	);
};

export default GenreComponent;
