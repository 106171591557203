import React, { startTransition, useState } from 'react';
import { useEffect } from 'react';
import { getFirestore, doc, getDoc, addDoc, collection } from 'firebase/firestore';
import firebase from '../../../Firebase/firebase';
import Card, {
	CardBody,
	CardTitle,
	CardSubTitle,
	CardLabel,
	CardFooter,
} from '../../bootstrap/Card';
import { useParams } from 'react-router-dom';
import Button from '../../bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Textarea from '../../bootstrap/forms/Textarea';
import showNotification from '../../extras/showNotification';

interface ViewASubmissionProps {
	submissionId: string;
}
const ViewASubmission = () => {
	const { id } = useParams();
	const { auth, db } = firebase;
	const [data, setData] = useState<any>(null);
	const [text, setText] = useState('');
	const navigate = useNavigate();
	const [modalState, setModalState] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
	};

	const handleModal = () => {
		setModalState(!modalState);
	};

	useEffect(() => {
		const fetchDocument = async () => {
			if (id != undefined) {
				const docRef = doc(db, 'submissions', id);
				try {
					const docSnap = await getDoc(docRef);
					if (docSnap.exists()) {
						setData(docSnap.data()); // Set the document data if found
					} else {
						console.log('No such document!');

						setData(null);
					}
				} catch (error) {
					console.error('Error fetching document:', error);
					// Set error if something goes wrong
				}
			}
		};

		if (id) {
			// Ensuring the docId is not null or undefined
			fetchDocument();
		}
	}, [id]); // Dependency array to trigger useEffect when docId changes
	if (!id || !data) return <div>Loading or ID is missing...</div>;

	const createSubmissionIssue = async (
		text: string,
		userEmail: string,
		SubmissionId: string | null | undefined,
	) => {
		try {
			const docRef = await addDoc(collection(db, 'submissionIssues'), {
				text: text,
				userEmail: userEmail,
				SubmissionId: SubmissionId,
			});
			showNotification('success', 'we will get back to you shortly', 'success');
			return docRef.id; // Return the document ID for further processing if necessary
		} catch (error) {
			console.error('Error adding document: ', error);
			showNotification('error', 'Error creating request', 'danger');
			throw error; // Rethrow the error for caller handling if needed
		}
	};
	return (
		<>
			<div className='w-100 text-center row '>
				<div className='d-flex justify-content-center'>
					<div className='d-flex justify-content-center align-items-center my-5 w-50 '>
						<Card stretch style={{ width: '100%' }}>
							<Button
								color='info'
								isLink
								icon='ArrowBack'
								onClick={() => navigate(-1)}>
								Back to my submissions
							</Button>

							<CardBody>
								<CardTitle>Submission {id}</CardTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Genre Selected:{' '}
									{data.instrumental !== undefined
										? data.genre.toString()
										: 'No genre selected please contact to fix'}
								</CardSubTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Number Of Files Uploaded: {data.numberOfFiles}
								</CardSubTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Explicity:{' '}
									{data.explicity !== undefined
										? data.explicity.toString()
										: 'Loading...'}
								</CardSubTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Instrumental:{' '}
									{data.instrumental !== undefined
										? data.explicity.toString()
										: 'Loading...'}
								</CardSubTitle>
								<div className='row'>
									<div className='col-lg-6 col-md-12'>
										<CardSubTitle>
											{data?.sitesToDistribute &&
											data.sitesToDistribute.length > 0 ? (
												<div className='container mt-4'>
													<table className='table table-dark table-hover'>
														<thead>
															<tr>
																<th>Sites distributed</th>{' '}
																{/* Add more columns if needed */}
															</tr>
														</thead>
														<tbody className=''>
															{data.sitesToDistribute.map(
																(site: any, index: number) => (
																	<tr key={index}>
																		{' '}
																		{/* Ensure you have a unique key for each row */}
																		<td>{site}</td>
																	</tr>
																),
															)}
														</tbody>
													</table>
												</div>
											) : (
												<p>No sites distributed.</p>
											)}
										</CardSubTitle>
									</div>
									<div className='col-lg-6 col-md-12'>
										<CardSubTitle>
											{data?.fileNames && data.fileNames.length > 0 ? (
												<div className='container mt-4'>
													<table className='table table-dark table-hover'>
														<thead>
															<tr>
																<th>Tracks Uploaded</th>{' '}
																{/* Add more columns if needed */}
															</tr>
														</thead>
														<tbody className=''>
															{data.fileNames.map(
																(name: any, index: number) => (
																	<tr key={index}>
																		{' '}
																		{/* Ensure you have a unique key for each row */}
																		<td>
																			{name.replace(
																				'.wav',
																				'',
																			)}
																		</td>
																	</tr>
																),
															)}
														</tbody>
													</table>
												</div>
											) : (
												<p>No sites distributed.</p>
											)}
										</CardSubTitle>
									</div>
								</div>
								<Button
									color='info'
									className='my-5'
									onClick={() => {
										handleModal();
									}}>
									Found an issue with your submission? Contact us now
								</Button>
							</CardBody>
						</Card>
					</div>
				</div>
				<Modal
					isOpen={modalState}
					setIsOpen={handleModal}
					titleId='exampleModalLabel'
					isStaticBackdrop={false}
					isScrollable={false}
					isCentered
					size='lg'
					fullScreen={false}
					isAnimation>
					<ModalHeader setIsOpen={handleModal}>
						<ModalTitle id='exampleModalLabel'>
							<strong>Report an issue</strong>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='col-12 shadow-3d-container'>
							<p className='h2'>ID: {id}</p>
							<p className='h2'>Account: {auth.currentUser?.email}</p>
							<div className='w-100 my-5'>
								<Textarea
									value={text}
									onChange={handleTextChange}
									placeholder='Enter your message here'
									style={{ height: '300px' }}></Textarea>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color='info'
							className='border-0'
							onClick={() => {
								createSubmissionIssue(text, id, auth.currentUser?.email);
							}}>
							Submit
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</>
	);
};

export default ViewASubmission;
