import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { getAnalytics } from 'firebase/analytics';
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	signInWithPopup,
	sendEmailVerification,
	User,
	onAuthStateChanged,
	reload,
} from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import firebase from '../../../Firebase/firebase';
import mrLogo from '../../../assets/img/blue 2 official.png';
import showNotification from '../../../components/extras/showNotification';
/**
 * LOGIN PAGE
 *
 * Firebase Auth is used to manage logins / outs
 *
 * This page allows a user to either sign in using Google, using email and password
 * or Sign up using email and password.
 *
 * The Signup function also creates a user document in the users collections
 */

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	// my stuff
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userEmail, setUserEmail] = useState('');
	const [userFirstName, setUserFirstName] = useState('');
	const [userLastName, setUserLastName] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [modalState, setModalState] = useState(false);
	const [userConfirmPassword, setUserConfirmPassword] = useState('');
	const [newUser, setNewUser] = useState<User | null>(null);
	const { auth } = firebase;
	const googleProvider = new GoogleAuthProvider();
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserEmail(e.target.value);
	};
	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserPassword(e.target.value);
	};
	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserFirstName(e.target.value);
	};
	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserLastName(e.target.value);
	};
	const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserConfirmPassword(e.target.value);
	};
	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in, see if the email has been verified.
				setNewUser(user); // This will update your newUser state with the latest user object
				if (user.emailVerified) {
					console.log('epic stuff');
				} else {
					console.log('Email not verified yet.');
				}
			} else {
				// User is signed out
				setNewUser(null);
			}
		});

		return () => unsubscribe(); // Cleanup subscription on unmount
	}, []);
	const signIn = () => {
		// first
		setIsLoading(true);
		signInWithEmailAndPassword(auth, userEmail, userPassword)
			.then((userCredential) => {
				// Signed in
				const { user } = userCredential;
				//
				setIsLoading(false);
				setSignInPassword(true);
				setUser?.(user);
				navigate('/');
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				formik.setFieldError('loginUsername', 'Credentials do not match');
				formik.setFieldError('loginPassword', 'Invalid credentials');
				setIsLoading(false);
			});
	};
	const isValidEmail = (email: string) => {
		const regex = new RegExp(
			/^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
		);
		return regex.test(email);
	};
	const setToFalse = () => {
		setEmailEmpty(false);
		setInvalidEmail(false);
		setFirstnameEmpty(false);
		setLastNameEmpty(false);
		setPasswordEmpty(false);
		setREpeatPasswordEmpty(false);
		setPasswordNotEqual(false);
	};
	const handleModal = () => {
		setModalState(!modalState);
	};

	const handleIsVerified = () => {
		if (newUser) {
			navigate('/');
			window.location.reload();
		} else {
			showNotification('Error', 'user not found', 'danger');
		}
	};
	const signUp = () => {
		setToFalse();
		setIsLoading(true);
		const emailTest = isValidEmail(userEmail);
		console.log(userEmail);
		if (userEmail === '') {
			setEmailEmpty(true);
			setIsLoading(false);
			return;
		}
		if (!emailTest) {
			setInvalidEmail(true);
			setIsLoading(false);
			return;
		}
		if (userFirstName === '') {
			setFirstnameEmpty(true);
			setIsLoading(false);
			return;
		}
		if (userLastName === '') {
			setLastNameEmpty(true);
			setIsLoading(false);
			return;
		}
		if (userPassword === '') {
			setPasswordEmpty(true);
			setIsLoading(false);
			return;
		}
		if (userConfirmPassword === '') {
			setREpeatPasswordEmpty(true);
			setIsLoading(false);
			return;
		}
		if (userPassword !== userConfirmPassword) {
			setPasswordNotEqual(true);
			setIsLoading(false);
			return;
		}
		createUserWithEmailAndPassword(auth, userEmail, userPassword)
			.then((userCredential) => {
				// Signed up successfully
				const { user } = userCredential;

				// Initialize Firestore
				const db = getFirestore();

				// Create a reference to the Firestore user document
				const userRef = doc(db, 'users', user.uid);

				// Set initial user data in Firestore
				setDoc(userRef, {
					userId: user.uid,
					numberOfFilesUploaded: 0,
					email: userEmail,
					activePlan: false,
					numberOfUploadsRemaining: 0,
					displayName: userEmail,
					userPhoneNumber: 'not provided',
					verified: false,
					amountToBePayed: 0,
					numberOfSubmissions: 0,
					stripeCustomerId: '',
					stripeSubscriptionId: '',
					currentPlanName: '',
					firstName: userFirstName,
					lastName: userLastName,
					// Initial number of files uploaded
					// Add other fields as needed
				})
					.then(() => {
						// Data stored successfully, navigate to home page
						setIsLoading(false);
						setModalState(true);
						sendEmailVerification(user);
						setNewUser(user);
					})
					.catch((error) => {
						console.error('Error storing user data:', error);
						setIsLoading(false);
					});
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(errorCode);
				console.log(errorMessage);
				formik.setFieldError('signupname', 'Invalid or already exists');
				setIsLoading(false);
				// Handle error
			});
	};
	const googleLogin = () => {
		setIsLoading(true);
		signInWithPopup(auth, googleProvider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				if (credential != null) {
					const token = credential.accessToken;
				}
				// The signed-in user info.
				const { user } = result;
				console.log(user.email);
				// set loading to false
				setIsLoading(false);

				navigate('/');
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const { email } = error.customData;
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(error);
				// ...
			});
	};

	// base shit

	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	//errors
	const [passwordEmpty, setPasswordEmpty] = useState(false);
	const [firstNameEmpty, setFirstnameEmpty] = useState(false);
	const [lastNameEmpty, setLastNameEmpty] = useState(false);
	const [repeatPasswordEmpty, setREpeatPasswordEmpty] = useState(false);
	const [passwordNotEqual, setPasswordNotEqual] = useState(false);
	const [emailEmpty, setEmailEmpty] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: USERS.JOHN.username,
			loginPassword: USERS.JOHN.password,
			signupemail: 'email@example.com',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string; signupemail?: string } =
				{};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}
			if (!values.signupemail) {
				errors.signupemail = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (usernameCheck(values.loginUsername)) {
				if (passwordCheck(values.loginUsername, values.loginPassword)) {
					handleOnClick();
				} else {
					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			}
		},
	});

	const handleContinue = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (
				!Object.keys(USERS).find(
					(f) => USERS[f].username.toString() === formik.values.loginUsername,
				)
			) {
				formik.setFieldError('loginUsername', 'No such user found in the system.');
			} else {
				setSignInPassword(true);
			}
			setIsLoading(false);
		}, 1000);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<img src={mrLogo} style={{ width: '200px' }} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<Alert isLight icon='Lock' isDismissible color='info'>
									<div className='row'>
										<div className='col-12'>
											{singUpStatus ? (
												<strong>Email verification required</strong>
											) : (
												<strong>Provide your details below</strong>
											)}
										</div>
									</div>
								</Alert>
								{emailEmpty && singUpStatus ? (
									<Alert isLight icon='Lock' isDismissible color='danger'>
										<div className='row'>
											<div className='col-12'>
												<strong>Email is empty</strong>
											</div>
										</div>
									</Alert>
								) : firstNameEmpty && singUpStatus ? (
									<Alert isLight icon='Lock' isDismissible color='danger'>
										<div className='row'>
											<div className='col-12'>
												<strong>First name is empty</strong>
											</div>
										</div>
									</Alert>
								) : lastNameEmpty && singUpStatus ? (
									<Alert isLight icon='Lock' isDismissible color='danger'>
										<div className='row'>
											<div className='col-12'>
												<strong>Last name is empty</strong>
											</div>
										</div>
									</Alert>
								) : passwordEmpty && singUpStatus ? (
									<Alert isLight icon='Lock' isDismissible color='danger'>
										<div className='row'>
											<div className='col-12'>
												<strong>Password is empty</strong>
											</div>
										</div>
									</Alert>
								) : repeatPasswordEmpty && singUpStatus ? (
									<Alert isLight icon='Lock' isDismissible color='danger'>
										<div className='row'>
											<div className='col-12'>
												<strong>Confirm password is empty</strong>
											</div>
										</div>
									</Alert>
								) : passwordNotEqual && singUpStatus ? (
									<Alert isLight icon='Lock' isDismissible color='danger'>
										<div className='row'>
											<div className='col-12'>
												<strong>Make sure the passwords match</strong>
											</div>
										</div>
									</Alert>
								) : invalidEmail && singUpStatus ? (
									<Alert isLight icon='Lock' isDismissible color='danger'>
										<div className='row'>
											<div className='col-12'>
												<strong>Email Invalid</strong>
											</div>
										</div>
									</Alert>
								) : (
									''
								)}
								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='signupemail'
													isFloating
													label='Your email'>
													<Input
														type='email'
														autoComplete='email'
														name='signupemail'
														value={userEmail}
														onChange={handleEmailChange}
														isValid={formik.isValid}
														isTouched={formik.touched.signupemail}
														onBlur={formik.handleBlur}
														invalidFeedback={formik.errors.signupemail}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signupemail'
													isFloating
													label='Your first name'>
													<Input
														type='email'
														autoComplete='email'
														name='signupname'
														value={userFirstName}
														onChange={handleFirstNameChange}
														isValid={formik.isValid}
														isTouched={formik.touched.signupemail}
														onBlur={formik.handleBlur}
														invalidFeedback={formik.errors.signupemail}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signupemail'
													isFloating
													label='Your last name'>
													<Input
														type='email'
														autoComplete='email'
														name='signuplastname'
														value={userLastName}
														onChange={handleLastNameChange}
														isValid={formik.isValid}
														isTouched={formik.touched.signupemail}
														onBlur={formik.handleBlur}
														invalidFeedback={formik.errors.signupemail}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>

											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
														name='password'
														value={userPassword}
														onChange={handlePasswordChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Re enter your password'>
													<Input
														type='password'
														autoComplete='password'
														name='confirmpassword'
														value={userConfirmPassword}
														onChange={handleConfirmPasswordChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='warning'
													className='w-100 py-3'
													isDisable={!formik.values.loginUsername}
													onClick={signUp}>
													{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
													Sign up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={userEmail}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={handleEmailChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>

												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													style={{ marginTop: '8px' }}
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={userPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={handlePasswordChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>

												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.loginUsername}.
													</div>
												)}
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={signIn}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														Login
													</Button>
												)}
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}

									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
			<Modal
				isOpen={modalState}
				setIsOpen={handleModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={true}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader>
					<ModalTitle id='exampleModalLabel'>
						<strong>Check your email</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Alert color='success' icon='warning'>
							Please click the link in your email to verify your account
						</Alert>

						<p>Once the link in the email has been clicked, press continue below.</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='info' onClick={handleIsVerified}>
						Continue
					</Button>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
