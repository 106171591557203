import React from 'react';
import Footer from '../../../layout/Footer/Footer';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';
import { CardTitle, CardSubTitle, CardLabel } from '../../../components/bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faYoutube,
	faTiktok,
	faTwitter,
	faDiscord,
} from '@fortawesome/free-brands-svg-icons';
const DefaultFooter = () => {
	return (
		<Footer>
			<div className='container-fluid d-flex justify-content-center'>
				<div className='row'>
					<div className='col'>
						<a
							href='https://www.tiktok.com/@melodyrights'
							target='_blank'
							aria-label='Follow us on Facebook'>
							<FontAwesomeIcon
								icon={faTiktok}
								style={{ fontSize: '20px' }}
								className='mx-5'
							/>
						</a>
						<a
							href='https://www.youtube.com/@MelodyRights'
							target='_blank'
							aria-label='Follow us on Facebook'>
							<FontAwesomeIcon
								icon={faYoutube}
								style={{ fontSize: '20px' }}
								className='mx-5'
							/>
						</a>
						<a
							href='https://www.twitter.com/@MelodyRights'
							target='_blank'
							aria-label='Follow us on Facebook'>
							<FontAwesomeIcon
								icon={faTwitter}
								style={{ fontSize: '20px' }}
								className='mx-5'
							/>
						</a>
						<a
							href='https://www.facebook.com/profile.php?id=61556419718414'
							target='_blank'
							aria-label='Follow us on Facebook'>
							<FontAwesomeIcon
								icon={faFacebook}
								style={{ fontSize: '20px' }}
								className='mx-5'
							/>
						</a>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
