import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Button from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../bootstrap/Card';
import { startTransition, useContext } from 'react';
import MRIMG from '../../../assets/img/wanna/susy/9BUv1VA5_400x400.png';
import Page from '../../../layout/Page/Page';
import ThemeContext from '../../../contexts/themeContext';
import MRImg4 from '../../../assets/img/wanna/susy/israel-palacio-Y20JJ_ddy9M-unsplash.jpg';

const HowMuchRoyalties = () => {
	const navigate = useNavigate();
	const { mobileDesign } = useContext(ThemeContext);
	return (
		<PageWrapper title='Blogs'>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back to blogs
					</Button>
					<SubheaderSeparator />
					<span className='text-muted'>Blog</span>
				</SubHeaderLeft>
			</SubHeader>
			<Card stretch data-tour='list' className='w-100'>
				<CardBody className='table-responsive' isScrollable>
					<div className='container mt-5'>
						<div className='row justify-content-center'>
							<div className='col-md-10'>
								<CardHeader>
									<CardLabel iconColor='info' className='mt-5 '>
										<CardTitle
											tag='div'
											className='display-6'
											style={
												mobileDesign
													? { fontSize: '40px' }
													: { fontSize: '60px' }
											}>
											How Much Royalties Do Songwriters Get: A Comprehensive
											Guide{' '}
										</CardTitle>
									</CardLabel>
								</CardHeader>
								{/* Image centered within the column */}
								<div className='d-flex justify-content-center'>
									<img
										src={MRImg4}
										className=' img-fluid'
										style={{ width: '50%', height: 'auto' }}
									/>
								</div>
							</div>
							<div className='col-md-7'>
								{/* Text content structured in repeated sections */}

								<div className='mt-5 '>
									<p className='h5'>
										While songwriting is a passion, turning it into a source of
										income can be challenging. If you're curious about how
										royalties work and what you can earn, you're not alone.
									</p>
									<p className='h5'>
										Royalties are a major way songwriters get paid, but the
										process can feel confusing and overwhelming.
									</p>
									<p className='h5'>
										In this post, we'll break down the different types of
										royalties, such as mechanical and performance royalties.
										We'll also explain how much you can expect to earn from
										things like streaming or live performances.
									</p>
									<p className='h5'>
										Understanding royalties is essential for both new and
										established musicians to earn a sustainable income from
										their music.
									</p>
									<p className='h5'>
										Let’s dive into the basics and help you get the most from
										your creative work.
									</p>
								</div>

								<p className='h1 mt-3'>Types of Royalties for Songwriters</p>
								<div>
									<p className='h5'>
										As a songwriter, you can earn various royalties for your
										creative work. Understanding these different revenue streams
										impacts your financial success in the music industry. Let's
										explore the main types of royalties you can collect.
									</p>
									<p className='h3 mt-3'>Mechanical Royalties</p>
									<p className='h5'>
										Mechanical royalties are payments you receive when your
										composition is reproduced, physically or digitally. These
										royalties are generated when your song is streamed on-demand
										or downloaded.
									</p>
									<p className='h5'>
										However, how these royalties are collected depends on where
										you’re based.
									</p>
									<p className='h3 mt-3'>In the U.S.</p>
									<p className='h5'>
										In the U.S., the Mechanical Licensing Collective (MLC)
										collects mechanical royalties for digital streaming.
										On-demand streams pay about $0.0006 each, requiring around
										1.6 million streams to earn $1,000. The MLC ensures artists
										are paid for their music played online.
									</p>
									<p className='h3 mt-3'>In the UK</p>
									<p className='h5'>
										PRS for Music handles performance royalties in the UK, while
										MCPS manages mechanical royalties for physical and digital
										reproduction. Streaming royalties are low, averaging about
										£0.004 per stream (Spotify).
									</p>
									<p className='h5'>
										Understanding where and how to collect your royalties can
										help maximise your income. Whether in the US or the UK,
										ensure you're registered with the right organisations to get
										paid for your hard work.
									</p>
									<p className='h5'>
										<a
											href='https://melodyrights.com/submit-email'
											target='_blank'>
											Click here for fast and easy registration.
										</a>
									</p>
									<p className='h3 mt-3'>Performance Royalties</p>
									<p className='h5'>
										Performance royalties are paid whenever your music is
										performed or broadcast publicly, including live shows and
										radio airplay. In the U.S., organisations like ASCAP and BMI
										handle the collection and distribution of these royalties,
										while PRS for Music fulfils this role in the U.K. To
										maximise these earnings, ensure you are registered with the
										appropriate PRO.
									</p>
								</div>
								<p className='h1 mt-3'>How Royalty Rates Are Determined </p>
								<div>
									<p className='h3'>1. Copyright Royalty Board</p>
									<p className='h5'>
										The Copyright Royalty Board (CRB) is crucial in determining
										mechanical royalty rates for songwriters in the US. The CRB
										is a panel of three judges that meets every five years to
										set these rates. They hear arguments from various
										stakeholders, such as songwriters, music publishers, record
										labels, and digital service providers (DSPs) like Spotify.
									</p>
									<p className='h5'>
										This increase benefits songwriters and music publishers who
										receive a larger portion of the revenue generated from
										streaming services..
									</p>
									<p className='h3'>2. Negotiated Rates</p>
									<p className='h5'>
										Not all royalty rates are set by the CRB. Some are
										negotiated, especially in cases like sync licensing. Sync
										licensing fees are negotiable one-time payments made to the
										master rights holder (the person or entity who owns the
										recording) and the songwriter or publisher. These fees are
										split into:
									</p>
									<p className='h5'>
										● Sync License Fee: For the use of the composition (the
										music and lyrics).
									</p>
									<p className='h5'>
										● Master Use License Fee: For the use of the actual
										recording.
									</p>
									<p className='h5'>
										These fees can vary greatly depending on factors such as the
										media in which the song is used (TV show, film,
										advertisement, etc.) and the song's or artist's popularity.
									</p>
									<p className='h3'>3. Streaming Platform Calculations</p>
									<p className='h5'>
										Streaming platforms typically use a "pro rata" system to
										calculate royalties. Under this model, the platform gathers
										all revenue from subscriptions and ad revenue and then
										divides it by the total number of streams.
									</p>
									<p className='h5'>
										Each stream earns a fractional royalty payment, depending on
										factors like the platform (Spotify, Apple Music, etc.), the
										type of listener (free vs. premium subscribers), the country
										where the stream happens, and even the song’s length.
									</p>
									<p className='h5'>
										For instance, Spotify pays performance royalties (collected
										by PROs like ASCAP and BMI) and mechanical royalties for
										on-demand streams.
									</p>
								</div>
								<p className='h1 mt-3'>Factors Affecting Songwriter Royalties</p>
								<div>
									<p className='h3'>Song Popularity</p>
									<p className='h5'>
										The popularity of a song directly influences the royalties
										earned. More streams or airplay leads to higher earnings.
										Songs featured in major films or hit TV shows often command
										higher rates than those used in less prominent contexts,
										such as local commercials.
									</p>
									<p className='h3'>Co-writing Arrangements</p>
									<p className='h5'>
										Co-writing is a common practice that adds complexity to
										royalty splits. When multiple songwriters collaborate, they
										typically share the royalties generated from their work.
										Unless specified otherwise, the law assumes that all
										contributors worked equally on the song, resulting in an
										even split. It's best to discuss and agree upon the royalty
										split while writing the song to avoid misunderstandings.
									</p>
									<p className='h3'>Publishing Deals</p>
									<p className='h5'>
										The type of publishing deal you have significantly affects
										how your royalties are managed. Publishing companies handle
										royalty collection and licensing, and the specifics of your
										agreement (such as co-publishing or administration) can
										impact your earnings.
									</p>
									<p className='h3'>Territory and Market</p>
									<p className='h5'>
										The location where a song is used influences royalty rates
										due to different laws in various countries. Emerging markets
										like China and India, with their large populations and
										increasing streaming adoption, represent potentially massive
										opportunities for industry growth.
									</p>
									<p className='h3'>Conclusion</p>
									<p className='h5'>
										Songwriter royalties are complex and ever-changing. It's
										crucial to understand different types, like mechanical and
										performance royalties, and factors that affect earnings,
										such as song popularity and publishing deals. The Copyright
										Royalty Board and streaming platforms also impact royalties.
									</p>
									<p className='h5'>
										Understanding songwriter royalties is crucial for maximising
										your income in the music industry. Stay informed about your
										rights, explore opportunities like sync licensing, and
										consider tools that simplify the process.
									</p>
								</div>

								<p className='h1 mt-3'>What Next? </p>
								<div>
									<p className='h3'>How Melody Rights Supports Songwriters</p>
									<p className='h5'>
										At Melody Rights, we understand songwriters' challenges in
										today’s complex music landscape. That’s why we’re dedicated
										to removing the burdensome administrative tasks often
										associated with music distribution. We believe your time
										should be spent creating music, not drowning in paperwork.
									</p>
									<p className='h5'>
										With our services, you won’t have to worry about tedious
										royalty registrations or account setups. We handle all the
										details, allowing your music to reach its full potential
										while you stay focused on your creative process. We aim to
										simplify the backend operations so you can concentrate on
										what you love most—making great music.
									</p>
									<p className='h5'>
										Join us at Melody Rights, and let us handle the
										administrative side of things, empowering you to thrive as a
										songwriter.
									</p>
									<p className='h5'>
										To learn more about how we can help, visit our official
										website.
									</p>
									<p className='h3'>How to collect royalties as a producer</p>
									<p className='h5'>
										Producers can collect royalties by joining a PRO like ASCAP
										or BMI for public performance royalties. Register with
										SoundExchange for sound recording royalties, and use the
										Mechanical Licensing Collective (MLC) for US mechanical
										royalties. A publisher is needed for international
										mechanical royalties.
									</p>
									<p className='h3'>
										How to collect international mechanical royalties
									</p>
									<p className='h5'>
										To collect international mechanical royalties, join the
										local mechanical collection societies in different
										countries. For example, SACEM in France and BUMA/STEMRA in
										the Netherlands manage these royalties. Rates vary by
										region, with Europe typically using a percentage-based
										system and the U.S. and Canada using fixed rates.
									</p>
									<p className='h5'>
										Ensure your songs are registered to avoid unclaimed
										royalties. Use Melody Rights today.
									</p>
									<p className='h3'>Who collects mechanical royalties?</p>
									<p className='h5'>
										The Mechanical Licensing Collective (MLC) is the primary
										organisation that collects mechanical royalties in the U.S.,
										managing licenses for streaming and download services. They
										gather data and royalties from Digital Service Providers
										(DSPs) monthly.
									</p>
									<p className='h5'>
										Different collection societies handle these royalties
										internationally, while organisations like the Harry Fox
										Agency (HFA) and Music Reports Inc. (MRI) play significant
										roles in the U.S. Independent artists often receive payments
										through distributors like Melody Rights.
									</p>
									<p className='h3'>Do producers get mechanical royalties?</p>
									<p className='h5'>
										Producers typically do not receive mechanical royalties
										directly, as these are designated for songwriters and
										publishers. However, producers can negotiate a share of
										these royalties if they contribute to the songwriting
										process. In some instances, they may receive a percentage of
										master recording royalties, which are separate from
										mechanical royalties.
									</p>
									<p className='h5'>
										To collect their share, producers need the artist to send a
										'Letter of Direction' to organisations like SoundExchange,
										outlining the percentage owed to the producer. If the
										producer co-writes the song, they may negotiate a
										percentage, but this split is subject to the contract and
										typically varies from song to song.
									</p>
									<p className='h3'>Does Spotify pay mechanical royalties?</p>
									<p className='h5'>
										Yes, Spotify pays mechanical royalties, but how they’re
										handled varies by country. In the U.S., Spotify pays
										mechanical royalties through the Mechanical Licensing
										Collective (MLC), which distributes them to rights holders.
										In the UK, Spotify pays both performance and mechanical
										royalties to PRS for Music and MCPS, which handle payments
										to songwriters, composers, and publishers.
									</p>
									<p className='h3'>
										How much royalties do songwriters get in the UK?
									</p>
									<p className='h5'>
										Songwriters in the UK typically earn royalties based on a
										percentage of revenue from music sales, streams, and public
										performances. PRS for Music and MCPS collect these
										royalties, with songwriters earning a percentage of the
										performance royalties and a negotiated share of mechanical
										royalties. The exact amount varies by song popularity,
										usage, and contractual agreements.
									</p>
									<p className='h3'>How much does a songwriter get per song?</p>
									<p className='h5'>
										The amount a songwriter earns per song depends on the song’s
										usage across sales, streams, and performances. In the U.S.,
										mechanical royalties for physical sales and downloads are
										12.4 cents per song under five minutes and 2.39 cents per
										minute over five minutes. These rates are typically split
										with co-writers and publishers. Streaming earnings add
										performance royalties, which vary based on the platform and
										territory, often managed by PROs and collecting societies.
									</p>
									<p className='h3'>Do songwriters get all the royalties?</p>
									<p className='h5'>
										Songwriters don’t receive all the royalties from a song, as
										earnings are split across various royalty types and shared
										with publishers or co-writers. In the U.S., mechanical
										royalties for physical sales and downloads apply to songs
										based on their length, and additional royalties are earned
										for performance in radio, streaming, and public plays
									</p>
									<p className='h5'>
										However, these royalties are typically split with publishers
										based on individual agreements.
									</p>
									<p className='h3'>How long do song royalties last?</p>
									<p className='h5'>
										In the United States, song royalties last for the duration
										of the copyright, which is the lifetime of the copyright
										holder plus an additional 70 years after their death. In the
										UK, copyright protection for music also extends for 70 years
										following the death of the creator.
									</p>
									<p className='h5'>
										This ensures that songwriters and their estates receive
										royalties for an extended period. Refer to the U.S.
										Copyright Office and the UK Copyright Service for more
										detailed information on copyright durations and their
										implications.
									</p>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		</PageWrapper>
	);
};

export default HowMuchRoyalties;
