import React from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import { CSSProperties } from 'react';

interface FileUploaderProps {
	onFilesAdded: (acceptedFiles: File[]) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFilesAdded }) => {
	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: onFilesAdded,
		multiple: true,
		accept: { 'audio/wav': ['.wav'] } as Accept,
	});

	const dropzoneStyle = {
		base: {
			width: '100%',
			height: '120px',
			padding: '40px',
			borderWidth: 5,
			borderColor: '#eeeeee',
			borderStyle: 'dashed',
			display: 'flex',
			justifyContent: 'center',
			borderRadius: '5px',
			backgroundColor: '#1B1B1B',
			color: '#bdbdbd',
			outline: 'none',
			transition: 'border .24s ease-in-out',
			cursor: 'pointer',
			textAlign: 'center' as const,
		} as CSSProperties,
		active: {
			borderColor: '#2196f3',
			width: '100%',
		} as CSSProperties,
		accept: {
			borderColor: '#00e676',
			width: '100%',
		} as CSSProperties,
		reject: {
			borderColor: '#ff1744',
		} as CSSProperties,
	};

	const getStyle = (): CSSProperties => ({
		...dropzoneStyle.base,
		...(isDragActive ? dropzoneStyle.active : {}),
		...(isDragAccept ? dropzoneStyle.accept : {}),
		...(isDragReject ? dropzoneStyle.reject : {}),
	});

	return (
		<div {...getRootProps({ style: getStyle() })}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<p> Drop the files here...</p>
			) : (
				<p>Drop 44.1 kHz 16-bit .wav files here, or click to select files</p>
			)}
		</div>
	);
};

export default FileUploader;
