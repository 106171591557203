import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Button from '../../bootstrap/Button';
import FileUploader from './FileUploader'; // Import the FileUploader component

interface UploadProps {
	setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
	numberOfFilesToUpload: number;
}

const storage = getStorage();

const Upload: React.FC<UploadProps> = ({ setUploadedFiles, numberOfFilesToUpload }) => {
	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

	const handleFilesAdded = (files: File[]) => {
		if (files.length > numberOfFilesToUpload) {
			alert(`Please upload no more than ${numberOfFilesToUpload} files`);
			return;
		}

		console.log('Files accepted:', files);
		setUploadedFiles(files); // Update the state with new files
		setFilesToUpload(files); // Set files to upload
	};

	const upload = async () => {
		for (const file of filesToUpload) {
			const storageRef = ref(storage, `uploads/${file.name}`);
			try {
				await uploadBytes(storageRef, file);
			} catch (error) {
				console.error('Error uploading file:', error);
			}
		}
	};

	const deleteImage = () => {
		setUploadedFiles([]);
		setFilesToUpload([]);
	};

	return (
		<Card>
			<CardBody>
				<div className='row g-4 align-items-center justify-content-center w-100'>
					<div className='col-12'>
						<FileUploader onFilesAdded={handleFilesAdded} />
					</div>

					<div className='col-12'>
						<p className='lead'>Scroll to view your uploaded tracks</p>
						<div className='col-auto'>
							<Button color='dark' isLight icon='Delete' onClick={deleteImage}>
								Delete Files
							</Button>
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default Upload;
